const evaluateResult = (agentScore, algoScore) => {
    if (agentScore === algoScore){
        switch (agentScore){
            case (1):
                return 'Your animal is extremely underweight and in poor health, action required'
                
            case (3):
                return 'Your animal is underweight and needs monitoring'


            case (5):
                return 'Perfect, your animal is under good condition'


            case (7):
                return 'Your animal is overweight and should be monitored'


            case (9):
                return 'Your animal is obese and in poor health, action required'

            default:
                // do nothing

        }
    }

    if (agentScore !== algoScore){
        return 'Sorry we cannot conclude the condition of the animal because there is a difference between assessors and algorithm scores.'
    }

    return 'System could not evaluate result!'
}

export default evaluateResult;