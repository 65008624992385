import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import useAxios from '../../api/useAxios';

import getCurrentDateAndTime from '../../utils/getCurrentDateTime';


const SummaryPage = ({ finalPage, dataEntryValues, BCSValue, imageValues }) => {

  document.title = 'Summary | BCS';

  const bcsValues = {
    1 : ' - Extremely Low',
    3 : ' - Low',
    5 : ' - Moderate',
    7 : ' - High',
    9 : ' - Extremely High'
  }

  const api = useAxios()

  const sendBCS = (payload) => {
    return api.post('/bcs/create', payload)
  }

  const createBCSMutation = useMutation(sendBCS, {
    onSuccess: (res) => {
      console.log(res)
      
      const output = res?.data?.data?.algorithm_output

      if (output === "Mismatched species in images"){
        toast.error('Mismatched species in images')
      }
      else{
        toast.success('Everything is good')

      }
      finalPage(res?.data?.data)
    },
    onError: (response) => {
      console.log(response)
      if (response?.response?.data?.message){
        toast.error(response?.response?.data?.message)
      }
      else{
        toast.error('Something went wrong')
      }
    }
  })



  const submitData = () => {

    let weight = 0;
    const animalID = dataEntryValues?.id ?? '0';


    if (dataEntryValues?.weight && dataEntryValues?.unit) {
      weight = dataEntryValues?.unit === 'KG' ? parseFloat(dataEntryValues.weight) * 1000 : dataEntryValues.weight
    }


    const remarks = dataEntryValues?.values ?? ''
    const backImage = imageValues?.backImage ?? '';
    const sideImage = imageValues?.sideImage ?? '';
    const agentScore = BCSValue?.bcs ?? ''


    const payload = {
      "animal_id": animalID,
      "remarks": remarks,
      "back_image": backImage,
      "side_image": sideImage,
      "agent_score": agentScore,
      "algorithm_score": 0,
      "expert_score": 0,
      "alert": "string",
      "weight": weight
    }

    createBCSMutation.mutate(payload)

  }


  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch
} = useQuery(`animalinfo-${dataEntryValues?.id}`, async () => api.get(`/animal/get/${dataEntryValues?.id}`), {enabled: !Boolean(dataEntryValues?.park)})


  useEffect(() => {
    window.scrollTo({top: 0, left:0, behavior: 'instant'}); 
  }, [])

  return (

    <div className="custom-container px-md-5 px-2 pt-md-5 pt-4 my-2 mb-4">

      <div className='row'>
        <div className="col-md-6 py-2 mb-2">
          <div className='p-2 border-green'>
            <h5 className='text-bold'>Date - Time</h5>
            {getCurrentDateAndTime()}
          </div>
        </div>

        <div className="col-md-6 py-2 mb-2">
          <div className='p-2 border-green'>
            <h5 className='text-bold'>Park</h5>
            {dataEntryValues?.park ? dataEntryValues.park : response?.data?.data?.park ? response?.data?.data?.park : 'N/A'}
          </div>
        </div>

        <div className="col-md-6 py-2 mb-2">
          <div className='p-2 border-green'>
            <h5 className='text-bold'>Species</h5>
            {dataEntryValues?.species ? dataEntryValues.species : response?.data?.data?.species ? response?.data?.data?.species : 'N/A'}
          </div>
        </div>

        <div className="col-md-6 py-2 mb-2">
          <div className='p-2 border-green'>
            <h5 className='text-bold'>Section</h5>
            {dataEntryValues?.section ? dataEntryValues.section : response?.data?.data?.section ? response?.data?.data?.section : 'N/A'}
          </div>
        </div>
        <div className="col-md-6 py-2 mb-2">
          <div className='p-2 border-green'>
            <h5 className='text-bold'>Animal ID</h5>
            {dataEntryValues?.animal_id ?? 'N/A'}
          </div>
        </div>

        <div className="col-md-6 py-2 mb-2">
          <div className='p-2 border-green'>
            <h5 className='text-bold'>BCS (Assessor's Score)</h5>
            {
            !BCSValue?.bcs ? 'N/A' : 
            BCSValue.bcs + bcsValues[BCSValue.bcs]
            } 
            
          </div>
        </div>

        <div className="col-md-6 py-2 mb-2">
          <div className='p-2 border-green'>
            <h5 className='text-bold'>Back Image</h5>
            <img src={imageValues.backImage} alt="Back" className="img-fluid border-green w-100" />
          </div>
        </div>

        <div className="col-md-6 py-2 mb-2">
          <div className='p-2 border-green'>
            <h5 className='text-bold'>Side Image</h5>
            <img src={imageValues.sideImage} alt="Side" className="img-fluid border-green w-100" />
          </div>
        </div>
      </div>


      <div className="text-end pb-md-5 pb-2 ">
        <button disabled={createBCSMutation.isLoading} onClick={submitData} className="btn btn-green">
          {
          createBCSMutation.isLoading ? 
            <> 
              Processing <Spinner size={'sm'} />
              </>
            : 
            'Complete' 
          }
        </button>
      </div>
    </div>

  );
};

export default SummaryPage;
