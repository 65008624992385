import React, { useState } from "react";

import { useFormik } from 'formik';

import { Label, Input } from 'reactstrap';

import { BCSSchema } from '../../validations/validation-schemas';

import ElephantGuideModalComponent from "./elephant-guide-modal.component";
import LemurGuideModalComponent from "./lemur-guide-modal.component";
import LionGuideModalComponent from "./lion-guide-modal.component";


const BCSComponent = ({ nextBtn, BCSValue, species }) => {

console.log(species)

const onSubmit = () =>{
        nextBtn(values)
}


const initialValues = {
    bcs: BCSValue.bcs,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: BCSSchema,
    onSubmit,
  });
  const [isGuideModal, setGuideModal] = useState(false)

  const toggleGuideModal = () => {
    setGuideModal(!isGuideModal)
  }

    return (
      <div className="custom-container px-md-5 px-4 pt-md-5 pt-4 my-2">
      
        <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 my-1">
                <button type="button" onClick={toggleGuideModal} className="btn btn-secondary mb-2">
                    Guide
                  </button>
                  <br />
                  <br />

                  <Label>BCS*</Label>
                  <Input
                    className={errors.bcs && touched.bcs ? "form-control form-control-icon is-invalid" : "form-control form-control-icon"}
                    id="bcs"
                    name="bcs"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bcs}
                    type='select'
                  >
                    <option value="" disabled>
                      Choose BCS
                    </option>
                    <option value="1">1 - Extremely Low</option>
                    <option value="3">3 - Low</option>
                    <option value="5">5 - Moderate</option>
                    <option value="7">7 - High</option>
                    <option value="9">9 - Extremely High</option>
                  </Input>
                  {touched.bcs && errors.bcs && (
                    <div className="text-danger">{errors.bcs}</div>
                  )}
                </div>

              </div>

              <div className="text-center mt-4">
                    <h5>Please score the animal based on your own observation.</h5>
              </div>

              <div className="text-end py-4">
                <button className="btn btn-green" type="submit">
                  Next
                </button>
              </div>
              

              {
                String(species).includes('Elephant') &&
                <ElephantGuideModalComponent
                isOpen={isGuideModal}
                toggle={toggleGuideModal}
                />
              }


              {
                String(species).includes('Lemur') &&
                  <LemurGuideModalComponent
                  isOpen={isGuideModal}
                  toggle={toggleGuideModal}
                  />
              }

              {
                String(species).includes('Lion') &&
                  <LionGuideModalComponent
                  isOpen={isGuideModal}
                  toggle={toggleGuideModal}
                  />
              }

            </form>
            </div>
    )

}

export default BCSComponent;