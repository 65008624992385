import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './asset/css/care-team/button.css';

import { QueryClient, QueryClientProvider } from 'react-query'

import Router from './routes';


import ThemeProvider from './theme';


import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import './asset/css/style.scss';
import './asset/css/utils.scss';
import './asset/css/buttons.scss';


import 'bootstrap/dist/css/bootstrap.css';


const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        staleTime: 10* 60 * 1000, // 10 minutes
        cacheTime: 10 * 60 * 1000 // 10 minutes
      }
    }
  }
)


// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <StyledChart />
          <Router />
          <ToastContainer />
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
