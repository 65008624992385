import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';

import { BsPencilFill, BsFillFileSpreadsheetFill } from 'react-icons/bs';

import { useQuery } from 'react-query';

import useAxios from '../../api/useAxios';
import ElephantGuideModalComponent from '../../components/staff/elephant-guide-modal.component';
import LemurGuideModalComponent from '../../components/staff/lemur-guide-modal.component';
import LionGuideModalComponent from '../../components/staff/lion-guide-modal.component';

import ModifyBCSModal from '../../components/common/modify-bcs-modal.component';
import { formatDate, convertTo24Hrs, convertToKG, formatTimestamp } from '../../utils/customFormatting';

const ExpertDashboard = () => {
  document.title = 'Verifier Dashboard | BCS';

  const [isGuideModalOpen, setGuideModalOpen] = useState(false);
  const [isModifyModalOpen, setModifyModalOpen] = useState(false);
  const [paginationIncomplete, setPaginationIncomplete] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [paginationComplete, setPaginationComplete] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const [activeBCS, setActiveBCS] = useState(false);

  const [activeSpecies, setActiveSpecies] = useState(false);

  const toggleModifyModal = () => {
    if (isModifyModalOpen) {
      setActiveBCS(false);
    }
    setModifyModalOpen(!isModifyModalOpen);
  };

  const toggleGuideModal = () => {
    if (isGuideModalOpen) {
      setActiveSpecies(false);
    }
    setGuideModalOpen(!isGuideModalOpen);
  };

  useEffect(() => {
    if (activeBCS) {
      setModifyModalOpen(true);
    }
  }, [activeBCS]);

  useEffect(() => {
    if (activeSpecies) {
      toggleGuideModal();
    }
  }, [activeSpecies]);

  const api = useAxios();

  const {
    isLoading: isIncompleteLoading,
    isSuccess: incompleteSuccess,
    data: bcsIncomplete,
    isError: isIncompleteError,
    error: incompleteError,
    refetch: incompleteRefetch,
  } = useQuery(`bcs-incomplete`, async () =>
    api.get(
      `bcs/get/incomplete?page=${paginationIncomplete.pageIndex > 0 ? paginationIncomplete.pageIndex + 1 : 1}&size=${
        paginationIncomplete.pageSize ?? 5
      }`
    )
  );

  useEffect(() => {
    incompleteRefetch();
  }, [paginationIncomplete.pageIndex, paginationIncomplete.pageSize]);

  const incompleteData = bcsIncomplete?.data?.items ?? [];
  const incompleteCount = bcsIncomplete?.data?.total ?? 0;

  const {
    isLoading: isCompleteLoading,
    isSuccess: completeSuccess,
    data: bcsComplete,
    isError: isCompleteError,
    error: completeError,
    refetch: completeRefetch,
  } = useQuery('bcs-complete', async () =>
    api.get(
      `bcs/get/completed?page=${paginationComplete.pageIndex > 0 ? paginationComplete.pageIndex + 1 : 1}&size=${
        paginationComplete.pageSize ?? 5
      }`
    )
  );

  useEffect(() => {
    completeRefetch();
  }, [paginationComplete.pageIndex, paginationComplete.pageSize]);

  const completeData = bcsComplete?.data?.items ?? [];
  const completeCount = bcsComplete?.data?.total ?? 0;

  console.log(completeData);

  const customRefetch = () => {
    incompleteRefetch();
    completeRefetch();
  };

  const incompleteColumns = useMemo(() => [
    {
      header: 'Date',
      accessorKey: 'last_updated_date',
      Cell: ({ row }) => formatDate(row?.original?.last_updated),
    },
    {
      header: 'Time',
      accessorKey: 'last_updated_time',
      Cell: ({ row }) => convertTo24Hrs(row?.original?.last_updated),
    },
    {
      header: 'Species',
      accessorKey: 'species',
    },
    {
      header: 'Assessor Name',
      accessorKey: 'assessor_name',
    },
    {
      header: 'Guide',
      Cell: ({ row }) => (
        <button onClick={() => setActiveSpecies(row.original.species)} className="btn btn-green cursor-pointer">
          <BsFillFileSpreadsheetFill />
        </button>
      ),
    },
    {
      header: 'Animal ID',
      accessorKey: 'animal_id',
    },
    {
      header: 'Weight',
      accessorKey: 'weight',
      Cell: ({ row }) => row?.original?.weight > 999 ? `${convertToKG(row?.original?.weight)} KG` : row?.original?.weight === 0 ? '-' : `${row?.original?.weight} G` ,
    },
    {
      header: 'Weight Taken Time',
      accessorKey: 'weight_taken',
      Cell: ({ row }) => formatTimestamp(row?.original?.weight_taken),
    },
    {
      header: 'Score',
      headerStyle: {
        border: '1px solid black',
      },
      columns: [
        {
          header: 'Assessor Score',
          accessorKey: 'agent_score',
        },
        {
          header: 'Algorithm Score',
          accessorKey: 'algorithm_score',
        },
        {
          header: 'Verifier Score',
          accessorKey: 'modify-button',
          Cell: ({ row }) => (
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => setActiveBCS(row.original)}
              className="btn btn-primary"
            >
              <BsPencilFill />
            </button>
          ),
        },
      ],
    },
  ]);

  const completeColumns = useMemo(() => [
    {
      header: 'Date',
      accessorKey: 'complete_last_updated_date',
      Cell: ({ row }) => formatDate(row?.original?.last_updated),
    },
    {
      header: 'Time',
      accessorKey: 'complete_last_updated_time',
      Cell: ({ row }) => convertTo24Hrs(row?.original?.last_updated),
    },
    {
      header: 'Verifier Name',
      accessorKey: 'verifier_name',
    },
    {
      header: 'Assessor Name',
      accessorKey: 'assessor_name',
    },
    {
      header: 'Species',
      accessorKey: 'species',
    },

    {
      header: 'Animal ID',
      accessorKey: 'animal_id',
    },
    {
      header: 'Weight',
      accessorKey: 'weight',
      Cell: ({ row }) => row?.original?.weight > 999 ? `${convertToKG(row?.original?.weight)} KG` : row?.original?.weight === 0 ? '-' : `${row?.original?.weight} G`,
    },
    {
      header: 'Weight Taken Time',
      accessorKey: 'animal_weight_taken',
      Cell: ({ row }) => formatTimestamp(row?.original?.animal_weight_taken),
    },
    {
      header: 'Score',
      headerStyle: {
        border: '1px solid black',
      },
      columns: [
        {
          header: 'Assessor Score',
          accessorKey: 'agent_score',
        },
        {
          header: 'Algorithm Score',
          accessorKey: 'algorithm_score',
        },
        {
          header: 'Verifier Score',
          accessorKey: 'expert_score',
        },
      ],
    },
    {
      header: 'Modify',
      accessorKey: 'modify',
      Cell: ({ row }) => (
        <button style={{ cursor: 'pointer' }} onClick={() => setActiveBCS(row.original)} className="btn btn-primary">
          <BsPencilFill />
        </button>
      ),
    },
  ]);



  return (
    <div className="container pb-4">
      <div className="container-fluid breadcrumb-color d-flex align-items-center mb-3 br-10 shadow">
        <nav aria-label="breadcrumb" className="d-flex align-items-center">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-white text-center active fs-4 pt-3 ps-2 text-bold" aria-current="page">
              Dashboard
            </li>
          </ol>
        </nav>
      </div>

      <div className="container-fluid my-md-3 my-2 bg-white pb-md-5 pb-3 custom-container">
        <h4 className="text-primary text-start m-3 pt-2">BCS Data Required</h4>

        <div className="container-fluid px-3 bg-white">
          <MaterialReactTable
            rowCount={incompleteCount}
            columns={incompleteColumns}
            state={{ isLoading: isIncompleteLoading, pagination: paginationIncomplete }}
            data={incompleteData}
            enableRowNumbers
            manualPagination
            onPaginationChange={setPaginationIncomplete}
            getRowId={(row) => row?.original?.id}
            muiTablePaperProps={{
              elevation: 5,
              sx: {
                borderRadius: '2px',
                border: '1px solid lightgray',
              },
            }}
            initialState={{
              columnPinning: { right: ['modify-button'] },
            }}
          />
        </div>
      </div>

      <div className="container-fluid bg-white pb-5 custom-container">
        <h4 className="text-primary text-start m-3 pt-2">BCS Data Completed</h4>

        <div className="container-fluid bg-white px-3">
          <MaterialReactTable
            rowCount={completeCount}
            columns={completeColumns}
            state={{ isLoading: isCompleteLoading, pagination: paginationComplete }}
            data={completeData}
            enableRowNumbers
            manualPagination
            onPaginationChange={setPaginationComplete}
            getRowId={(row) => row?.original?.id}
            muiTablePaperProps={{
              elevation: 5,
              sx: {
                borderRadius: '2px',
                border: '1px solid lightgray',
              },
            }}
            initialState={{
              columnPinning: { right: ['modify'] },
            }}
          />
        </div>
      </div>

      {String(activeSpecies).includes('Elephant') && (
  <ElephantGuideModalComponent isOpen={isGuideModalOpen} toggle={toggleGuideModal} />
)}

{String(activeSpecies).includes('Lion') && (
  <LionGuideModalComponent isOpen={isGuideModalOpen} toggle={toggleGuideModal} />
)}

{String(activeSpecies).includes('Lemur') && (
  <LemurGuideModalComponent isOpen={isGuideModalOpen} toggle={toggleGuideModal} />
)}



      <ModifyBCSModal isOpen={isModifyModalOpen} toggle={toggleModifyModal} data={activeBCS} refetch={customRefetch} />
    </div>
  );
};

export default ExpertDashboard;
