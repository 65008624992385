import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import { Label, Input, Spinner } from 'reactstrap';

import { useMutation } from 'react-query';

import useAxios from '../../api/useAxios';
import { animalSchema } from '../../validations/validation-schemas';
import getCurrentDateAndTime from '../../utils/getCurrentDateTime'

const AdminAnimalRegistration = () => {
  document.title = 'Animal Registration | BCS';

  const onSubmit = () => {


    let weight;
    if (values.unit === 'kg'){
      weight = values.weight*1000
    }
    else{
      weight = values.weight
    }

    const payload = {
      park: values?.park,
      section: values?.section,
      species: values?.species,
      animal_id: values?.animal_id,
      gender: values?.gender,
      weight,
      
      date_of_birth: values?.date_of_birth,
    }

    console.log(payload)

    animalRegMutation.mutate(payload)
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      park: '',
      section: '',
      species: '',
      animal_id: '',
      gender: '',
      weight: '',
      unit: '',
      date_of_birth: '',
    },
    validationSchema: animalSchema,
    onSubmit,
    enableReinitialize: true,
  })

  const api = useAxios()

  const animalReg = (payload) => {
    console.log(payload)
    return api.post('/animal/create', payload)
  }

  const animalRegMutation = useMutation(animalReg, {
    onSuccess: (response) => {
      console.log(response)
      toast.success('Animal added')
      resetForm()
    },
    onError: (response) => {
      
        console.log(response)
        toast.error('Something went wrong')
    }
  })
  
  const today = new Date().toISOString().split('T')[0];

  // // State to manage the options for the second Input based on the selected park
  const [sectionOptions, setSectionOptions] = useState([]);

  // // Your existing handleChange function
  const handleChange1 = (event) => {
    const { name, value } = event.target;
    // Update the selected park
    if (name === 'park') {

      // You can customize the options for the second Input based on the selected park
      // For example, you might fetch options from an API or define them statically
      // Here, I'm just using static options for illustration

      let newSectionOptions = [];
      if (value === 'Night Safari') {
        newSectionOptions = ["Primate 1","Primate 2","Primate 3","Carnivore 1","Marsupial 2","Herbivore 4","Herbivore 5","Elephant 1","Herpetology","Invertebrate","RFB Show","SS Show","AB Show"];
      } else if (value === 'Singapore Zoo') {
        newSectionOptions = ["Carnivore 2","Carnivore 3","Small Mammal 1","Small Mammal 2","Marsupial 1","Herbivore 1","Herbivore 2","Elephant 2","CoN Show"];
      } else if (value === 'River Wonders') {
        newSectionOptions = ["RW Terrestrial","Panda","Aquatic 1","Aquatic 2","Aquatic 3","Aquatic 4","Jewel Tank","OUaR Show"];
      }
      setSectionOptions(newSectionOptions);
      handleChange(event);
    }

    // Your existing logic for other fields
    // ...
  };

  return (
    <div className='container'>
    <div className="container-fluid breadcrumb-color d-flex align-items-center mb-3" style={{ borderRadius: '10px' }}>
      <nav aria-label="breadcrumb" className="d-flex align-items-center">
        <ol className="breadcrumb">
          <li
            className="breadcrumb-item text-white text-center active fs-4 pt-3 ps-2 br-10"
            aria-current="page">
            Animal Registration
          </li>
        </ol>
      </nav>
    </div>
    <div className="container-fluid bg-white pb-3 custom-container">
        
        <form onSubmit={handleSubmit}>
            <div className='row p-3'>
              
              <div className="col-12 col-md-6 mt-3">
                <Label>Park*</Label>             
                <Input
                    value={values.park}
                    onChange={handleChange1}
                    onBlur={handleBlur}
                    type="select"
                    className={errors.park && touched.park ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="parkInput"
                    name="park"
                  >
                    <option value="">Choose</option>
                    <option value="Night Safari">Night Safari</option>
                    <option value="Singapore Zoo">Singapore Zoo</option>
                    <option value="River Wonders">River Wonders</option>
                    </Input>
                   {
                    errors.park && touched.park && <div className="invalid-feedback">{errors.park}</div>
                    }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Section*</Label>           
                <Input
                    value={values.section}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    placeholder="Enter Section"
                    className={errors.section && touched.section ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="sectionInput"
                    name="section"
                  >
                    <option value="">Choose</option>
                    {sectionOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Input>
                   {
                      errors.section && touched.section && <div className="invalid-feedback">{errors.section}</div>
                   }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Species*</Label>             
                <Input
                    value={values.species}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    placeholder="Enter Species"
                    className={errors.species && touched.species ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="speciesInput"
                    name="species"
                  >
                      <option value="">Select Species</option>
                      <option value="African Lion (Panthera leo)">African Lion (Panthera leo)</option>
                        <option value="Asian Elephant (Elephas maximus)">Asian Elephant (Elephas maximus)</option>
                        <option value="Ring-tailed lemur (Lemur catta)">Ring-tailed lemur (Lemur catta)</option>
                  </Input>

                   {
                    errors.species && touched.species && <div className="invalid-feedback">{errors.species}</div>
                  }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Animal ID*</Label>             
                <Input
                    value={values.animal_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Animal ID"
                    className={errors.animal_id && touched.animal_id ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="animal_idInput"
                    name="animal_id"
                  />
                   {
                    errors.animal_id && touched.animal_id && <div className="invalid-feedback">{errors.animal_id}</div>
                  }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Gender*</Label>
                <Input
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={errors.gender && touched.gender ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="genderInput"
                    name="gender"
                  >
                    <option value="">Choose</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    </Input>
                   {
                    errors.gender && touched.gender && <div className="invalid-feedback">{errors.gender}</div>
                  }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <div className='row'>
                  <div className='col-8'>
                      <Label>Latest Weight*</Label>
                      <Input
                          value={values.weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="number"
                          className={errors.weight && touched.weight ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                          id="weightInput"
                          placeholder='Enter Weight'
                          name="weight"
                          min={0}
                        />
                        {
                          errors.weight && touched.weight && <div className="invalid-feedback">{errors.weight}</div>
                        }
                  </div>
                  <div className='col-4'>
                  <Label>Unit*</Label>             
                      <Input
                          value={values.unit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="select"
                          className={errors.unit && touched.unit ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                          id="unitInput"
                          name="unit"
                        >
                          <option value="">Choose</option>
                          <option value="g">G</option>
                          <option value="kg">Kg</option>
                          </Input>
                        {
                          errors.unit && touched.unit && <div className="invalid-feedback">{errors.unit}</div>
                        }
                  </div>
                </div>
              </div>
            
              <div className="col-12 col-md-6 mt-3">
                <Label>Date of Birth*</Label>             
                <Input
                    value={values.date_of_birth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    className={errors.date_of_birth && touched.date_of_birth ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="date_of_birthInput"
                    name="date_of_birth"
                    max={today}
                  />
                   {
                    errors.date_of_birth && touched.date_of_birth && <div className="invalid-feedback">{errors.date_of_birth}</div>
                  }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Date & Time</Label>             
                <Input
                    value={getCurrentDateAndTime()}
                    className={"form-control form-control-icon col-6"}
                    id="date_of_birthInput"
                    name="date_of_birth"
                    disabled                    
                  />
              </div>

            </div>
              
            <div className='text-start ms-3'>
            <button disabled={animalRegMutation.isLoading} type='submit' className="btn btn-green">
                    {animalRegMutation.isLoading ? <>Processing <Spinner size='sm'/></> : 'Register'}
                </button>
            </div>

        </form>

    </div>
  </div>
  );
};

export default AdminAnimalRegistration;
