import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { Label, Input } from 'reactstrap';

import { useQuery } from "react-query";
import useAxios from '../../api/useAxios';


import { dataEntryValidationSchema } from '../../validations/validation-schemas';

const DataEntryComponent = ({ nextBtn, dataEntryValues }) => {


    const [parkOptions, setParkOptions] = useState({})
    const [sectionOptions, setSectionOptions] = useState([])
    const [speciesOptions, setSpeciesOptions] = useState([])


    const onSubmit = () =>{

        if ((values.weight && values.unit) || (!values.weight && !values.unit)){
            nextBtn(values)
        }
        else{
            toast.error('Either enter weight and unit both or leave')
        }

    }
   
    const initialValues = {
        park: dataEntryValues.park,
        section: dataEntryValues.section,
        species: dataEntryValues.species,
        id: dataEntryValues.id,
        animal_id: dataEntryValues.animal_id,
        weight: dataEntryValues.weight,
        unit: dataEntryValues.unit,
        remarks: dataEntryValues.remarks,
      };



      const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: dataEntryValidationSchema,
        onSubmit,
      });


      const api = useAxios()

      const {
        isLoading,
        isError,
        data: response,
        isSuccess,
        refetch
    } = useQuery(`parkSectionSpecies`, async () => api.get('/animal/get/park_section_species'))

    const {
        isLoading: IDsLoading,
        isError: isIDsError,
        data: IDs,
        isSuccess: isIDsSuccess,
        refetch: refetchIDs
    } = useQuery(`ids`, async () => api.get(`/animal/get/all?filter_by_park=${values.park}&filter_by_section=${values.section}&filter_by_specie=${values.species}`), {cacheTime: 0})

    const animalOptions = IDs?.data?.items ?? []

    function getObjectById(targetId) {
        const foundID = animalOptions.find(obj => obj.animal_id === targetId) || null;
        
        if (foundID?.id){
            setFieldValue('id', foundID?.id)
        }
    }


    useEffect(() => {
        
        if (!!values.animal_id){
            getObjectById(values.animal_id)
        }

    }, [values.animal_id])

   
    const serverOptions = response?.data?.data ?? []

    // setting park options upon server response
    useEffect(() => {
        if (isSuccess) {
            setParkOptions(serverOptions);
        }
    }, [response]);
    
    

    useEffect(() => {
        
        const parkValue = values.park

        const sectionsArray = Object.keys(serverOptions)

        if (values.park && sectionsArray.includes(parkValue)){
            setSectionOptions(Object.keys(serverOptions[values.park]))
            refetchIDs()
        }
        else{
            setSectionOptions([])
            setSpeciesOptions([])
        }
    }, [values.park])



    useEffect(() => {

        if (values.section){
            setSpeciesOptions(Object.values((serverOptions[values?.park])[values?.section]))
            refetchIDs()
        }
        else{
            setSpeciesOptions([])
        }
    }, [values.section])

    useEffect(() => {
            refetchIDs()
    }, [values.species])

    useEffect(() => {
        if (values.id){
            if (!values.park){
                setFieldValue('park', '')
            }
        }
    }, [values.id])


    return (
        <div className="custom-container px-md-5 px-4 pt-md-5 pt-4 my-2">
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12 my-1">
                    <Label className="mt-2 mb-0">Park</Label>
                    <Input
                        className={errors.park && touched.park ? "form-control form-control-icon is-invalid" : "form-control form-control-icon"}
                        id="park"
                        name="park"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.park}
                        type='select'
                    >
                        <option value="">Choose</option>
                        <option value="Night Safari">Night Safari</option>
                        <option value="Singapore Zoo">Singapore Zoo</option>
                        <option value="River Wonders">River Wonders</option>
                        </Input>
                        {touched.park && errors.park && (
                            <div className="text-danger">{errors.park}</div>
                        )}
                </div>

                <div className="col-12 my-1">
                    <Label className="mt-2 mb-0">Section</Label>
                    <Input
                        className={errors.section && touched.section ? "form-control form-control-icon is-invalid" : "form-control form-control-icon"}
                        id="section"
                        name="section"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.section}
                        type='select'
                    >
                        <option value="">
                            Choose Section
                        </option>
                        {sectionOptions.map((section, index) => (
                            <option key={index} value={section}>{section}</option>
                        ))}
                    </Input>
                    {touched.section && errors.section && (
                        <div className="text-danger">{errors.section}</div>
                    )}
                </div>

                <div className="col-12 my-1">
                    <Label className="mt-2 mb-0">Species</Label>
                    <Input
                        className={errors.species && touched.species ? "form-control form-control-icon is-invalid" : "form-control form-control-icon"}
                        id="species"
                        name="species"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.species}
                        type='select'
                    >
                        <option value="">
                            Choose Species
                        </option>
                        {speciesOptions.map((species, index) => (
                            <option key={index} value={species}>{species}</option>
                        ))}
                    </Input>
                    {touched.species && errors.species && (
                        <div className="text-danger">{errors.species}</div>
                    )}
                </div>

                <div className="col-12 my-1">
                    <Label className="mt-2 mb-0">Animal ID*</Label>
                    <Input
                        className={errors.animal_id && touched.animal_id ? "form-control form-control-icon is-invalid" : "form-control form-control-icon"}
                        id="animal_id"
                        name="animal_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.animal_id}
                        type='select'
                    >
                        <option value="">
                            Choose Animal ID
                        </option>
                        {animalOptions.map((animalOption, index) => (
                            <option key={index} value={animalOption.animal_id}>{animalOption.animal_id}</option>
                        ))                            
                        }
                    </Input>
                    {touched.id && errors.id && (
                        <div className="text-danger">{errors.id}</div>
                    )}
                </div>


                <div className="col-12 my-1">
                    <div className='row'>
                        <div className='col-8'>
                            <Label className="mt-2 mb-0">Latest Weight</Label>
                            <Input
                                className={errors.weight && touched.weight ? "form-control form-control-icon is-invalid" : "form-control form-control-icon"}
                                id="weight"
                                name="weight"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.weight}
                                type='number'
                                placeholder='Enter Weight'
                                min={0}
                            />
                            {touched.weight && errors.weight && (
                                <div className="text-danger">{errors.weight}</div>
                            )}
                        </div>

                        <div className='col-4'>
                            <Label className="mt-2 mb-0">Unit</Label>
                            <Input
                                className={errors.unit && touched.unit ? "form-control form-control-icon is-invalid" : "form-control form-control-icon"}
                                id="unit"
                                name="unit"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.unit}
                                type='select'
                            >
                                <option value="">Choose Unit</option>
                                <option value="G">G</option>
                                <option value="KG">Kg</option>
                            </Input>
                            {touched.unit && errors.unit && (
                                <div className="text-danger">{errors.unit}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-12 my-1">
                    <Label className="mt-2 mb-0">Remarks</Label>
                    <Input
                        id="remarks"
                        name="remarks"
                        className={errors.remarks && touched.remarks ? "form-control form-control-icon is-invalid" : "form-control form-control-icon"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.remarks}
                        type='textarea'
                        placeholder='Animal Height or any other remarks.'
                    />
                    {touched.remarks && errors.remarks && (
                        <div className="text-danger">{errors.remarks}</div>
                    )}
                </div>

                <p className="mb-0 mt-2"><em>Latest weight and remarks are optional fields.</em></p>
            </div>

            <div className="text-end py-4">
                <button type="submit" className="btn btn-green">
                    Next
                </button>
            </div>
        </form>
        </div>
    )
}

export default DataEntryComponent;