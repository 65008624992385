import {create} from "zustand";
import { persist, devtools } from "zustand/middleware";


export const useStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        userType: null,
        accessToken: null,
        refreshToken: null,
        name: null,
        emai: null
      }),
      {
        name: "mandaistorage",
        getStorage: () => localStorage,
      }
    )
  )
);