export default function getCurrentFormattedDateAndTime() {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const currentDateTime = new Date().toLocaleDateString('en-US', options);
  return currentDateTime;
}