import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiArrowFatLeftFill } from 'react-icons/pi';

import DataEntryComponent from '../../components/staff/data-entry.component';
import BCSComponent from '../../components/staff/bcs-component';

import ImagesCapturing from './images-page';
import ImagesTemp from './images-page-temp';
import SummaryPage from './summary-page';
import ScoringChart from './scoring-chart';

import GuideModalComponent from '../../components/staff/elephant-guide-modal.component';


const DataEntry = () => {
  document.title = 'Data Entry | BCS';

  const components = ['dataEntry', 'bcs', 'images', 'summary', 'csc']

  const [currentComponent, setCurrentComponent] = useState('dataEntry')
  const [guideModal, setGuideModal] = useState(false)

  const [dataEntryValues, setDataEntryValues] = useState({ park: "", section: "", species: "", id: "", weight: "", unit: "", remarks: "" })
  const [BCSValue, setBCSValue] = useState({bcs: ""})
  const [imageValues, setImageValues] = useState({ backImage: "", sideImage: ""})

  const [finalValues, setFinalValues] = useState(false)

  const toggleGuideModal = () => {
    setGuideModal(!guideModal)
  }


  const moveNext = () => {
    const index = components.indexOf(currentComponent)
    setCurrentComponent(components[index + 1])
  }

  const moveToFirstComponent = () => {
    setDataEntryValues({ park: "", section: "", species: "", id: "", weight: "", unit: "", remarks: "" })
    setBCSValue({bcs: ""})
    setImageValues({ backImage: "", sideImage: ""})
    setFinalValues(false)
    setCurrentComponent('dataEntry')
  }

  const nextBtn = (values) => {
    
    switch (currentComponent){
      case 'dataEntry':
        setDataEntryValues(values)
        moveNext()
        break;
        
      case 'bcs':
        setBCSValue(values)
        moveNext()
        break;
    
      case 'images':
        setImageValues(values)
        moveNext()
        break;

      case 'summary':
        console.log('need to submit the form')
        break;

      default:
        // console.log('not found')

    }
  }


  const finalPage = (values) => {
    setFinalValues({
      'species': values?.specie ?? 'N/A',
      'agentScore': values?.agent_score ?? 'N/A',
      'algorithmScore': values?.algorithm_score ?? 'N/A',
    })
  }
  
  useEffect(() => {
    
    if (finalValues){
      moveNext()
    }

  }, [finalValues])


  const navigate = useNavigate()

  const backBtn = () => {
    const index = components.indexOf(currentComponent)
    if (index === 0){
      navigate('/staff/alerts')
    }
    else {
      setCurrentComponent(components[index - 1])
    }
  }


  return (
    <div className='container'>
      <div className="container-fluid breadcrumb-color d-flex align-items-center mb-3 br-10 shadow">
        <nav aria-label="breadcrumb" className="d-flex align-items-center">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item text-white text-center active fs-4 pt-3 ps-2 text-bold d-flex align-items-center"
              aria-current="page">

              <PiArrowFatLeftFill size={30} className='back-btn' onClick={backBtn} />
              <span className='ms-3'>
                {currentComponent === 'dataEntry' && 'Enter Data'}
                {currentComponent === 'bcs' && 'Body Condition Scoring'}
                {currentComponent === 'images' && 'Images'}
                {currentComponent === 'summary' && 'Summary'}
                {currentComponent === 'csc' && 'Body Condition Scoring Chart'}
              </span>

            </li>
          </ol>
        </nav>
      </div>



      <div className="pb-md-5 pb-0">

  {currentComponent === 'dataEntry' &&
            <DataEntryComponent
              nextBtn={nextBtn}
              dataEntryValues={dataEntryValues}
            /> 
          }
          {
            currentComponent === 'bcs' &&
                <BCSComponent
                  nextBtn={nextBtn}
                  BCSValue={BCSValue}
                  species={dataEntryValues?.species}
                  />
                } 

          {
            currentComponent === 'images' && 
            <ImagesTemp
            nextBtn={nextBtn}
            imageValues={imageValues}
            species={dataEntryValues?.species}
            />
        }

          {
            currentComponent === 'summary' && 
            <SummaryPage 
              finalPage={finalPage}
              dataEntryValues={dataEntryValues}
              BCSValue={BCSValue}
              imageValues={imageValues}
            />
          }

          {
            currentComponent === 'csc' &&
            <ScoringChart
              nextBtn={nextBtn}
              finalValues={finalValues}
              moveToFirstComponent={moveToFirstComponent}
            />
          }
      </div>
          <GuideModalComponent
            isOpen={guideModal}
            toggle={toggleGuideModal}
          />

    </div>
  );
};

export default DataEntry;
