import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import { useMutation } from 'react-query';

import { userModifySchema } from '../../validations/validation-schemas';

import useAxios from '../../api/useAxios';

const UserModal = ({ isOpen, toggle, user, refetch }) => {
  
  const [modalStyles, setModalStyles] = useState({
    top: '20%',
    zIndex: '1',
  });

  const onSubmit = () => {

    
    if (values.name === user.name){
      delete values.name
    }
    
    if (values.email === user.email){
      delete values.email
    }

    if (values.role_id === user.role_id){
      delete values.role_id
    }

    if (Object.keys(values).length === 0){
      toast.warning('Nothing changed')
      toggle()
    }

    else{
      userModifyMutation.mutate(values)

    }
  }


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      name: user?.name,
      email: user?.email,
      role_id: user?.role_id,
      department: user?.department
    },
    validationSchema: userModifySchema,
    onSubmit,
    enableReinitialize: true,
  })

  const api = useAxios()

  const userModify = (payload) => {
    return api.patch(`/user/update/${user?.id}`, payload)
  }

  const userModifyMutation = useMutation(userModify, {
    onSuccess: (response) => {
      console.log(response)
      toast.success('User Modified')
      toggle()
      resetForm()
      refetch()
    },
    onError: (response) => {
      
      console.log(response)
        toast.error('Something went wrong')
    }
  })


  return (
    <Modal size='lg' isOpen={isOpen} toggle={toggle} style={modalStyles}>
      <form onSubmit={handleSubmit}>
      <ModalHeader toggle={toggle}>User Modification</ModalHeader>
      <ModalBody>
            <div className='row'>
              
            <div className="col-12 col-md-6 mt-3">
                  <Label>Name*</Label>             
                  <Input
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      placeholder="Enter user's name"
                      className={errors.name && touched.name ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                      id="nameInput"
                      name="name"
                    />
                    {
                      errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>
                    }
                </div>
               
                <div className="col-12 col-md-6 mt-3">
                  <Label>User Role*</Label>                  
                  <Input
                    value={values.role_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={errors.role_id && touched.role_id ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="role_idInput"
                    name="role_id"
                  >
                    <option value="">Choose</option>
                    <option value="3">Verifier</option>
                    <option value="4">Assessor</option>
                  </Input>
                  {
                    errors.role_id && touched.role_id && <div className="invalid-feedback">{errors.role_id}</div>
                  }

                </div>
               
                <div className="col-12 col-md-6 mt-3">
                  <Label>Email Address*</Label>                  
                  <Input
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    placeholder="Enter user's email"
                    className={errors.email && touched.email ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="emailInput"
                    name="email"
                  />
                   {
                    errors.email && touched.email && <div className="invalid-feedback">{errors.email}</div>
                  }

                </div>
                
                <div className="col-12 col-md-6 mt-3">
                  <Label>Department*</Label>                  
                  <Input
                    value={values.department}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={errors.department && touched.department ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="departmentInput"
                    name="department"
                  > 
                      <option value="">Select Department</option>
                      <option value="Animal Care">Animal Care</option>
                      <option value="Veterinary Healthcare">Veterinary Healthcare</option>
                      <option value="Animal Behaviour & Programmes">Animal Behaviour & Programmes</option>
                  </Input>
                  
                  {
                    errors.department && touched.department && <div className="invalid-feedback">{errors.department}</div>
                  }
                </div>

            </div>


      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button disabled={userModifyMutation.isLoading} type='submit' className="btn btn-green">
          {userModifyMutation.isLoading ? <>Modifying <Spinner size={'sm'}/></> : 'Modify'}
        </Button>
      </ModalFooter>
        </form>
    </Modal>
  );
};

export default UserModal;
