import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import { useMutation } from 'react-query';

import { animalSchema } from '../../validations/validation-schemas';

import useAxios from '../../api/useAxios';

import { convertToKG } from '../../utils/customFormatting';

import getCurrentDateAndTime from '../../utils/getCurrentDateTime'

const AnimalModal = ({ isOpen, toggle, animal, refetch }) => {

  const [modalStyles, setModalStyles] = useState({
    zIndex: '1',
  });

  const onSubmit = () => {
    
    if (values?.unit === 'kg'){
      values.weight *= 1000
    }

    if (values?.unit){
      delete values.unit
    }

    animalModifyMutation.mutate(values)
  }


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      park: animal?.park,
      section: animal?.section,
      species: animal?.species,
      animal_id: animal?.animal_id,
      gender: animal?.gender,
      weight: animal?.weight > 999 ? convertToKG(animal.weight) : animal.weight,
      unit: animal?.weight > 999 ? 'kg': 'g',
      date_of_birth: animal?.date_of_birth,
    },
    validationSchema: animalSchema,
    onSubmit,
    enableReinitialize: true,
  })

  const api = useAxios()

  const animalModify = (payload) => {
    console.log(payload)
    return api.patch(`/animal/update/${animal?.id}`, payload)
  }

  const animalModifyMutation = useMutation(animalModify, {
    onSuccess: (response) => {
      console.log(response)
      toast.success('Animal Modified')
      toggle()
      resetForm()
      refetch()
    },
    onError: (response) => {
      
      console.log(response)
        toast.error('Something went wrong')
    }
  })
  
  const today = new Date().toISOString().split('T')[0]; 


  return (
    <Modal size='lg' isOpen={isOpen} toggle={toggle} style={modalStyles}>
      <form onSubmit={handleSubmit}>
      <ModalHeader toggle={toggle}>Animal Modification</ModalHeader>
      <ModalBody>
            <div className='row'>
              
              <div className="col-12 col-md-6 mt-3">
                <Label>Park*</Label>             
                <Input
                    value={values.park}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Park"
                    className={errors.park && touched.park ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="parkInput"
                    name="park"
                  />
                   {
                    errors.park && touched.park && <div className="invalid-feedback">{errors.park}</div>
                    }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Section*</Label>             
                <Input
                    value={values.section}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Section"
                    className={errors.section && touched.section ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="sectionInput"
                    name="section"
                  />
                   {
                    errors.section && touched.section && <div className="invalid-feedback">{errors.section}</div>
                  }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Species*</Label>             
                <Input
                    value={values.species}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    placeholder="Enter Species"
                    className={errors.species && touched.species ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="speciesInput"
                    name="species"
                  >
                      <option value="">Select Species</option>
                        <option value="African Lion (Panthera leo)">African Lion (Panthera leo)</option>
                        <option value="Asian Elephant (Elephas maximus)">Asian Elephant (Elephas maximus)</option>
                        <option value="Ring-tailed lemur (Lemur catta)">Ring-tailed lemur (Lemur catta)</option>
                  </Input>
                   {
                    errors.species && touched.species && <div className="invalid-feedback">{errors.species}</div>
                  }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Animal ID*</Label>             
                <Input
                    value={values.animal_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Animal ID"
                    className={errors.animal_id && touched.animal_id ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="animal_idInput"
                    name="animal_id"
                  />
                   {
                    errors.animal_id && touched.animal_id && <div className="invalid-feedback">{errors.animal_id}</div>
                  }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <Label>Gender*</Label>             
                <Input
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={errors.gender && touched.gender ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="genderInput"
                    name="gender"
                  >
                    <option value="">Choose</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    </Input>
                   {
                    errors.gender && touched.gender && <div className="invalid-feedback">{errors.gender}</div>
                  }
              </div>

              <div className="col-12 col-md-6 mt-3">
                <div className='row'>

                  <div className='col-8'>
                  <Label>Weight*</Label>
                      <Input
                          value={values.weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="number"
                          className={errors.weight && touched.weight ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                          id="weightInput"
                          placeholder='Enter Weight'
                          name="weight"
                          min={0}
                        />
                        {
                          errors.weight && touched.weight && <div className="invalid-feedback">{errors.weight}</div>
                        }
                  </div>
                  <div className='col-4'>
                  <Label>Unit*</Label>             
                      <Input
                          value={values.unit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="select"
                          className={errors.unit && touched.unit ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                          id="unitInput"
                          name="unit"
                        >
                          <option value="">Choose</option>
                          <option value="g">G</option>
                          <option value="kg">Kg</option>
                          </Input>
                        {
                          errors.unit && touched.unit && <div className="invalid-feedback">{errors.unit}</div>
                        }
                  </div>
                </div>
              </div>
            
              <div className="col-12 col-md-6 mt-3">
                <Label>Date of Birth*</Label>             
                <Input
                    value={values.date_of_birth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    className={errors.date_of_birth && touched.date_of_birth ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="date_of_birthInput"
                    name="date_of_birth"
                    max={today}
                  />
                   {
                    errors.date_of_birth && touched.date_of_birth && <div className="invalid-feedback">{errors.date_of_birth}</div>
                  }
              </div>
             
              <div className="col-12 col-md-6 mt-3">
                <Label>Date & Time</Label>             
                <Input
                    value={getCurrentDateAndTime()}
                    className={"form-control form-control-icon col-6"}
                    id="date_of_birthInput"
                    name="date_of_birth"
                    disabled                    
                  />
              </div>

            </div>


      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button disabled={animalModifyMutation.isLoading} type='submit' className="btn btn-green">
          {animalModifyMutation.isLoading ? <>Modifying <Spinner size={'sm'}/></> : 'Modify'}
        </Button>
      </ModalFooter>
        </form>
    </Modal>
  );
};

export default AnimalModal;
