import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import { alertModalSchema } from '../../validations/validation-schemas';

import  image1 from '../../asset/animals/1.jpeg'
import  image3 from '../../asset/animals/3.jpeg'
import  image5 from '../../asset/animals/5.jpeg'
import  image7 from '../../asset/animals/7.jpeg'
import  image9 from '../../asset/animals/9.jpeg'


const ElephantGuideModalComponent = ({ isOpen, toggle }) => {
  const formik = useFormik({
    initialValues: {
      user: '',
      remarks: '',
    },
    validationSchema: alertModalSchema,
    onSubmit: (values) => {
        console.log('submitting')
    },
  });

  const [modalStyles, setModalStyles] = useState({
    zIndex: '1',
  });


  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size='lg' style={modalStyles}>
      <ModalHeader toggle={toggle}>BCS Guide - Asian elephant (Elephas maximus)</ModalHeader>
      <ModalBody>

          <table className='guide-table'>
          

            <tr>
                <th>BCS</th>
                <th>Ribs</th>
                <th>Pelvic Bone</th>
                <th>Backbone</th>
                <th>Example Photographs</th>
            </tr>
            
            <tr>
                <td className='bcs-score'>1</td>
                <td>Individual ribs clearly visible</td>
                <td>Protrudes, deep depression in front and behind pelvic bone</td>
                <td>Protrudes from tail head to shoulders, deep depression alongside entire backbone</td>
                <td><img src={image1} alt="" /></td>
            </tr>

            <tr>
                <td className='bcs-score'>3</td>
                <td>Some ribs may be noticeable, ribs appear to be covered by a very thin fat layer</td>
                <td>Clearly visible, obvious depression in front and/or behind pelvic bone</td>
                <td>Prominent from tail head to shoulders, obvious depression alongside entire backbone</td>
                <td><img src={image3} alt="" /></td>
            </tr>
            
            <tr>
                <td className='bcs-score'>5</td>
                <td>Not visible</td>
                <td>Visible, entire pelvic bone may not be visible, slight depression in front of pelvic bone</td>
                <td>Clearly visible from tail head to shoulders, fat beginning to accumulate alongside backbone</td>
                <td><img src={image5} alt="" /></td>
            </tr>
           
            <tr>
                <td className='bcs-score'>7</td>
                <td>Not visible</td>
                <td>Slight definition and not entirely visible, slight sunken or flattened area in front of pelvic bone</td>
                <td>Visible as a ridge, some regions of the backbone more visible than others due to fat accumulation</td>
                <td><img src={image7} alt="" /></td>
            </tr>
           
            <tr>
                <td className='bcs-score'>9</td>
                <td>Not visible</td>
                <td>Not visible</td>
                <td>Not visible or difficult to differentiate, area alongside backbone is filled in giving a round appearance</td>
                <td><img src={image9} alt="" /></td>
            </tr>

          </table>
        
      </ModalBody>
      <ModalFooter>
        <buton className='btn btn-green' onClick={toggle}>
          Close
        </buton>
      </ModalFooter>
      <ToastContainer /> {/* Include ToastContainer to display toasts */}
    </Modal>
  );
};

export default ElephantGuideModalComponent;
