import { useState , useEffect} from 'react';

import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../../components/logo';
import FooterComponent from '../../components/footer/footer';

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {



  const [marginStyle, setMarginStyle] = useState({});
  useEffect(() => {
    const updateMargin = () => {
      const screenSize = window.innerWidth;
      if (screenSize <= 768) {
        setMarginStyle({ marginLeft: '0', marginRight: '0' });
      } else {
        setMarginStyle({ marginLeft: '-16px', marginRight: '-16px' });
      }
    };
    updateMargin();
    window.addEventListener('resize', updateMargin);

    return () => {
      window.removeEventListener('resize', updateMargin);
    };
  }, []);





  return (
    <>
      <StyledHeader>
        {/* <Logo /> */}
      </StyledHeader>

      <Outlet />
      <div style={marginStyle}>
        <FooterComponent />
      </div>
    </>
  );
}
