import axios from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import IP from '../../utils/variables';


const useResetPassMutation = () => {

  const URL = `${window.location.protocol}//${IP}`;

  const sendResetPass = (payload) => {
    return axios.patch(`${URL}/user/forgot_password`, payload);
  };


  const resetPassMutation = useMutation(sendResetPass, {
    onSuccess: (response) => {
          if (response?.data?.message === 'No user found.'){
            toast.error(response.data.message)
          }
          
          else if (response?.data?.message === 'New Password sent to your email.')
          {
            
            toast.success(response.data.message)
          }
          else{
            toast.error('Something went wrong.')
          }
    },
    onError: (data) => {

        console.log(data) 
        toast.error('Something went wrong')
    },
  });

  return resetPassMutation;
};

export default useResetPassMutation;