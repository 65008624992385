import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';

import { BsPencilFill } from 'react-icons/bs';

import frontImage from '../../asset/images/dashbaord_images/front-side-image.jpg';
import backImage from '../../asset/images/dashbaord_images/back-side-image.jpg';

const ExpertBCS = () => {
  document.title = 'Verifer BCS | BCS';

  const [isModalOpen, setModalOpen] = useState(false);
  const [editedExpertScore, setEditedExpertScore] = useState(0);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onSave = (newExpertScore) => {
    setEditedExpertScore(newExpertScore);
  };

  const dataRequired = [
    {
      details: {
        serial: '1',
        animal: 'Lion',
        species: 'XX',
        id: 'XXXXX',
        fieldTeamScore: '1',
        algorithmScore: '3',
        expertScore: <BsPencilFill onClick={openModal} style={{ cursor: 'pointer' }} />,
      },
    },
    {
      details: {
        serial: '1',
        animal: 'Elephant',
        species: 'XX',
        id: 'XXXXX',
        fieldTeamScore: '1',
        algorithmScore: '3',
        expertScore: <BsPencilFill onClick={openModal} style={{ cursor: 'pointer' }} />,
      },
    },
  ];

  const dataCompleted = [
    {
      details: {
        serial: '1',
        animal: 'Lion',
        species: 'XX',
        id: 'XXXXX',
        fieldTeamScore: '1',
        algorithmScore: '3',
        expertScore: editedExpertScore,
      },
    },
    {
      details: {
        serial: '1',
        animal: 'Elephant',
        species: 'XX',
        id: 'XXXXX',
        fieldTeamScore: '1',
        algorithmScore: '3',
        expertScore: editedExpertScore,
      },
    },
  ];

  const columnsRequired = useMemo(
    () => [
      {
        accessorKey: 'details.serial',
        header: '#',
        size: 50,
      },
      {
        accessorKey: 'details.animal',
        header: 'Animal',
        size: 120,
      },
      {
        accessorKey: 'details.species',
        header: 'Species',
        size: 120,
      },
      {
        accessorKey: 'details.id',
        header: 'ID',
        size: 120,
      },
      {
        accessorKey: 'details.fieldTeamScore',
        header: 'Field Team Score',
        size: 120,
      },
      {
        accessorKey: 'details.algorithmScore',
        header: 'Algorithm Score',
        size: 120,
      },
      {
        accessorKey: 'details.expertScore',
        header: 'Verifier Score',
        size: 120,
      },
    ],
    [[editedExpertScore]]
  );

  const columnsCompleted = useMemo(
    () => [
      {
        accessorKey: 'details.serial',
        header: '#',
        size: 50,
      },
      {
        accessorKey: 'details.animal',
        header: 'Animal',
        size: 120,
      },
      {
        accessorKey: 'details.species',
        header: 'Species',
        size: 120,
      },
      {
        accessorKey: 'details.id',
        header: 'ID',
        size: 120,
      },
      {
        accessorKey: 'details.fieldTeamScore',
        header: 'Field Team Score',
        size: 120,
      },
      {
        accessorKey: 'details.algorithmScore',
        header: 'Algorithm Score',
        size: 120,
      },
      {
        accessorKey: 'details.expertScore',
        header: 'Verifier Score',
        size: 120,
      },
    ],
    [[editedExpertScore]]
  );

  return (
    <>
      <div className="container breadcrumb-color d-flex align-items-center mb-3" style={{ borderRadius: '10px' }}>
        <nav aria-label="breadcrumb" className="d-flex align-items-center">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item text-white text-center active fs-4 pt-3 ps-2"
              aria-current="page"
              style={{ fontWeight: '800', borderRadius: '10px' }}
            >
              Body Condition Scoring
            </li>
          </ol>
        </nav>
      </div>

      <div
        className="container my-md-5 my-4 bg-white pb-3"
        style={{ border: '3px solid lightgrey', borderRadius: '10px' }}
      >
        <div className="row">
          <div className="d-flex align-items-center">
            <div className="col-12">
              <h4 className="text-center mt-4">BCS Data Required</h4>
            </div>
          </div>
        </div>

        <div className="container p-3 bg-white">
          <MaterialReactTable
            columns={columnsRequired}
            data={dataRequired}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: '5px',
                border: '2px solid lightgrey',
              },
            }}
          />

        </div>

        <div className="row mx-4">
          <div className="col-md-3">
            <div className="my-md-5 my-4">
              <h5>Front Side</h5>

              <img src={frontImage} alt="Not found" className="img-fluid rounded" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="my-md-5 my-4">
              <h5>Back Side</h5>
              <img src={backImage} alt="Not found" className="img-fluid rounded" />
            </div>
          </div>

          <div className="col-md-4">
            <div>
              <h5 className="mt-md-5 mt-2">Remarks</h5>
              <textarea rows="5" className="form-control" placeholder="Enter additional information..." />
            </div>
          </div>
        </div>
      </div>

      <div className="container bg-white pb-3" style={{ border: '2px solid lightgrey', borderRadius: '10px' }}>
        <div className="row">
          <div className="d-flex align-items-center">
            <div className="col-12">
              <h4 className="text-center mt-4">BCS Data Completed</h4>
            </div>
          </div>
        </div>

        <div className="container bg-white p-3">
          <MaterialReactTable
            columns={columnsCompleted}
            data={dataCompleted}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: '5px',
                border: '2px solid lightgrey',
              },
            }}
          />
        </div>

        <div className="row mx-4">
          <div className="col-md-3">
            <div className="my-md-5 my-4">
              <h5>Back Side</h5>
              <img src="https://picsum.photos/250/200" alt="Not Found" className="rounded" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="my-md-5 my-4">
              <h5>Back Side</h5>
              <img src="https://picsum.photos/250/200" alt="Not Found" className="rounded" />
            </div>
          </div>

          <div className="col-md-4">
            <div>
              <h5 className="mt-md-5 mt-2">Remarks</h5>
              <textarea rows="5" className="form-control" placeholder="Enter additional information..." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpertBCS;
