import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import { alertModalSchema } from '../../validations/validation-schemas';


import image1 from '../../asset/animals/lemur/1.png'
import image3 from '../../asset/animals/lemur/3.png'
import image5 from '../../asset/animals/lemur/5.png'
import image7 from '../../asset/animals/lemur/7.png'
import image9 from '../../asset/animals/lemur/9.png'


const LemurGuideModalComponent = ({ isOpen, toggle }) => {
  const formik = useFormik({
    initialValues: {
      user: '',
      remarks: '',
    },
    validationSchema: alertModalSchema,
    onSubmit: (values) => {
        console.log('submitting')
    },
  });

  const [modalStyles, setModalStyles] = useState({
    zIndex: '1',
  });



  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size='lg' style={modalStyles}>
      <ModalHeader toggle={toggle}>BCS Guide - Lemur (Lemures)</ModalHeader>
      <ModalBody>

          <table className='guide-table'>
            
            <tr>
                <th>BCS</th>
                <th>Neck and Head</th>
                <th>Abdomen</th>
                <th>Hip Bones</th>
                <th>Example Photographs</th>
            </tr>
            
            <tr>
                <td className='bcs-score'>1</td>
                <td>Neck is much thinner than head. Prominent facial bones, sunken eyes.</td>
                <td>Prominent waist and abdomen tucked up</td>
                <td>Very prominent hip bones.</td>
                <td><img src={image1} alt="" /></td>
            </tr>

            <tr>
                <td className='bcs-score'>3</td>
                <td>Neck is thinner than head.</td>
                <td>Prominent waist and abdomen tucked up</td>
                <td>Prominent hips and decreased rounding of flank</td>
                <td><img src={image3} alt="" /></td>
            </tr>
            
            <tr>
                <td className='bcs-score'>5</td>
                <td>Neck and head similar in size.</td>
                <td>Gently tucked abdomen from the side</td>
                <td>Hips are visible but not prominent. Flank is rounded</td>
                <td><img src={image5} alt="" /></td>
            </tr>
           
            <tr>
                <td className='bcs-score'>7</td>
                <td>Neck may be wider than head.</td>
                <td>Abdominal fat is obvious- rounded/saggy</td>
                <td>Hips have Increased rounding </td>
                <td><img src={image7} alt="" /></td>
            </tr>
           
            <tr>
                <td className='bcs-score'>9</td>
                <td>Prominent fat in neck-rolls may be present</td>
                <td>Obvious abdominal distension</td>
                <td>Fat rolls present on limbs.</td>
                <td><img src={image9} alt="" /></td>
            </tr>

          </table>
        
      </ModalBody>
      <ModalFooter>
        <buton className='btn btn-green' onClick={toggle}>
          Close
        </buton>
      </ModalFooter>
      <ToastContainer /> {/* Include ToastContainer to display toasts */}
    </Modal>
  );
};

export default LemurGuideModalComponent;
