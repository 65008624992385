import axios from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import IP from '../../utils/variables';
import { useStore } from '../../store/useStore';


const useLoginMutation = () => {

  const navigate = useNavigate()

  const URL = `https://${IP}`;

  const sendLogin = (payload) => {
    return axios.post(`${URL}/auth/login`, payload);
  };


  const loginMutation = useMutation(sendLogin, {
    onSuccess: (response) => {

        if (response?.status === 200) {

            const data = response?.data?.data

            if (!data?.access_token || !data?.refresh_token) {
              toast.error('Something went wrong. Please try again');
              return; // Stop further execution if tokens are missing
          }


            if (data){    
                useStore.setState({ userStatus: true })
                useStore.setState({ accessToken: data?.access_token })
                useStore.setState({ refreshToken: data?.refresh_token })
                useStore.setState({ name: data?.user_data?.name ?? 'N/A'})
                useStore.setState({ email: data?.user_data?.email ?? 'N/A'})
                
                switch (data?.redirect_page){
                    case '/agent':
                        useStore.setState({ userType: 'agent' })
                        navigate('/staff/alerts')
                        break;
                        
                    case '/expert':
                        useStore.setState({ userType: 'expert' })
                        navigate('/expert/dashboard')
                        break;
                        
                    case '/admin':
                        useStore.setState({ userType: 'admin' })
                        navigate('/admin/dashboard')
                        break;
                    
                    default:
                        // do nothing
                }
            }
            else{
                toast.error('Something went wrong')
            }

        }

    //   const tokens = data?.data?.tokens ?? false;

    //   if (tokens) {
    //     useStore.setState({ userStatus: true });
    //     useStore.setState({ accessToken: tokens.access });
    //     useStore.setState({ refreshToken: tokens.refresh });

    //     navigate('/admin/guest-registration');
    //   } else {
    //     toast.error('Something went wrong');
    //   }
    },
    onError: (data) => {

        console.log(data)

      if (data?.response?.status === 401) {
        toast.error('Invalid Credentials');
      } else {
        toast.error('Something went wrong');
      }
    },
  });

  return loginMutation;
};

export default useLoginMutation;