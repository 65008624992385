import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Iconify from '../../../components/iconify';

import logo1 from '../../../asset/images/logos/mandai-white.png';

const NAV_WIDTH = 280;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
  [theme.breakpoints.up('lg')]: {},
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {

  return (
    <StyledRoot
      sx={{
        width: '100%',
        zIndex: 3
      }}
    > 
    
      <StyledToolbar>

        <img src={logo1} alt="Not found" className="img-fluid" width="150px" />
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
