import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Label, Input } from 'reactstrap';

import { toast } from 'react-toastify';

import { useMutation } from 'react-query';
import { useFormik } from 'formik';

import useAxios from '../../api/useAxios';

import { passwordSchema } from '../../validations/validation-schemas';



const ChangePasswordModal = ({ isOpen, toggle }) => {


    const api = useAxios()

    const changePassword = (payload) => {
        return api.patch('/user/change_password', payload)
    }

    const passwordMutation = useMutation(changePassword, {
        onSuccess: (response) => {
          toast.success('Updated')
          toggle()
          resetForm()
        },
        onError: (response) => {
            
            if (response?.response?.data?.message && response.response.data.message === 'Old password is incorrect.'){
              toast.error('Incorrect Old Password')
            }

            else if (response?.response?.status === 422 && response?.response?.data?.detail[0]?.message === 'ensure this value has at least 8 characters'){
                toast.error('Password must be atleast 8 characters')
            }

            else{
              toast.error('Something went wrong')
            }
        }
    })

    const onSubmit = () => {
      passwordMutation.mutate(values)
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
        initialValues: {
          old_password: '',
          new_password: '',
          confirm_new_password: ''
        },
        validationSchema: passwordSchema,
        onSubmit,
        enableReinitialize: true,
      })

      useEffect(() => {
        // Set body overflow to 'auto' when the modal is closed
        if (!isOpen) {
          resetForm()
          document.body.style.overflow = 'auto';
        }
      
        // Cleanup function to reset the style when the component unmounts
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [isOpen]);
      
  
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
          <form onSubmit={handleSubmit}>
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
      <ModalBody>
        <div className='row'>
            <div className='col-12'>
                <Label>Old Password*</Label>
                <Input
                    value={values.old_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    placeholder="Enter Old Password"
                    className={errors.old_password && touched.old_password ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="old_passwordInput"
                    name="old_password"
                  />
                   {
                    errors.old_password && touched.old_password && <div className="invalid-feedback">{errors.old_password}</div>
                    }
            </div>
          
            <div className='col-12'>
                <Label className='mt-3 mb-0'>New Password*</Label>
                <Input
                    value={values.new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    placeholder="Enter New Password"
                    className={errors.new_password && touched.new_password ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="new_passwordInput"
                    name="new_password"
                  />
                   {
                    errors.new_password && touched.new_password && <div className="invalid-feedback">{errors.new_password}</div>
                    }
            </div>
          
            <div className='col-12'>
                <Label className='mt-3 mb-0'>Confirm New Password*</Label>
                <Input
                    value={values.confirm_new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    placeholder="Confirm New Password"
                    className={errors.confirm_new_password && touched.confirm_new_password ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="confirm_new_passwordInput"
                    name="confirm_new_password"
                  />
                   {
                    errors.confirm_new_password && touched.confirm_new_password && <div className="invalid-feedback">{errors.confirm_new_password}</div>
                    }
            </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={passwordMutation.isLoading} color="secondary" onClick={toggle}>
          Close
        </Button>
        <button type='submit' disabled={passwordMutation.isLoading} className="btn btn-green">
          {passwordMutation.isLoading ? <>Changing <Spinner size={'sm'}/></> : 'Change'}
        </button>
      </ModalFooter>
            </form> 
    </Modal>
  );
};

export default ChangePasswordModal;
