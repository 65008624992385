import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';

import { useQuery } from 'react-query';

import useAxios from '../../api/useAxios';

const AlertPage = () => {
  document.title = 'Staff Alerts | BCS';

  const [tableData, setTableData] = useState([]);

  const alertsColumns = useMemo(
    () => [
      {
        accessorKey: 'alert',
        header: 'Alert',
      },
    ],
    []
  );

    const navigate = useNavigate()

    const api = useAxios()

    const {
      isLoading,
      isError,
      data: response,
      isSuccess,
      refetch
  } = useQuery(`alerts`, async () => api.get('/bcs/alerts'))


  const alerts = response?.data?.items ?? []

  useEffect(() => {

    if (isSuccess) {
      const mappedData = alerts.map((alert, index) => ({
        'alert': alert,
      }));

      setTableData(mappedData);

    }
  }, [alerts, isSuccess]);


  return (
    <div className='container'>
        <div className="container-fluid breadcrumb-color d-flex align-items-center mb-3 br-10 shadow">
          <nav aria-label="breadcrumb" className="d-flex align-items-center">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item text-white text-center active fs-4 pt-3 ps-2 text-bold"
                aria-current="page">
                Alerts
              </li>
            </ol>
          </nav>
        </div>

      <div className="container-fluid bg-white p-3 my-md-3 my-2 custom-container">

        <div className="container-fluid bg-white p-3">
          {
            isError ? 'Something went wrong' : 
          
          <MaterialReactTable
            columns={alertsColumns}
            data={tableData}
            layoutMode={'grid'}
            enableRowNumbers
            muiTablePaperProps={{
              elevation: 5,
              sx: {
                border: '1px solid lightgrey',
              },
            }}
            state={{isLoading}}
          />
          }
          
        </div>

        <div className="text-end me-4"> 
          <button onClick={() => navigate('/staff/data-entry')} className="btn btn-green">
            Go to Score
          </button>
        </div>
        
      </div>
    </div>
  );
};

export default AlertPage;
