import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import useAxios from '../../api/useAxios';


import ElephantGuideModalComponent from '../../components/staff/elephant-guide-modal.component';
import LemurGuideModalComponent from '../../components/staff/lemur-guide-modal.component';
import LionGuideModalComponent from '../../components/staff/lion-guide-modal.component';

import blurDetector from '../../utils/blurDetection';
import BlurModal from '../../components/common/blur-modal';

const ImagesTemp = ({ nextBtn, imageValues, species }) => {
  const api = useAxios() 

  document.title = 'Images Capturing | BCS';


  const [imgSrcBack, setImgSrcBack] = useState(imageValues.backImage);
  const [imgSrcSide, setImgSrcSide] = useState(imageValues.sideImage);
  const [showNextButton, setShowNextButton] = useState(false);
  const [isGuideModal, setGuideModal] = useState(false);
  const [isBlurModal, setBlurModal] = useState(false);

  const inputBackRef = useRef(null);
  const inputSideRef = useRef(null);

  const toggleGuideModal = () => {
    setGuideModal(!isGuideModal);
  };

  const toggleBlurModal = () => {
    setBlurModal(!isBlurModal);
  };

  const blurImageDetectormodel = (result, imageType, imageSrc, inputBackRef, inputSideRef) => {
    if (result) {
      toggleBlurModal();
      
      if (imageType === 'back' && inputBackRef.current) {
        setImgSrcBack(null);
        inputBackRef.current.value = '';
      } else if (imageType === 'side' && inputSideRef.current) {
        setImgSrcSide(null);
        inputSideRef.current.value = '';
      }}


  else {
      if (imageType === 'back') {
        setImgSrcBack(imageSrc);
        
      } else if (imageType === 'side') {
        setImgSrcSide(imageSrc);
        
      }

      setShowNextButton(true);
    }
  };

  const blurImageDetector = (payload) => {
    return api.post('/animal/verify/image', {"image_hash":payload.image_hash})
  }

  const blurImageDetectorMutation = useMutation(blurImageDetector, {
    onSuccess: (res, variable) => {
      const { _, imageType, imageSrc, inputBackRef, inputSideRef } = variable;
      blurImageDetectormodel(res?.data?.data?.blurry, imageType, imageSrc, inputBackRef, inputSideRef)
      
    },
    onError: (response) => {
      console.log(response)
      if (response?.response?.data?.message){
        toast.error(response?.response?.data?.message)
      }
      else{
        toast.error('Something went wrong')
      }
    }
  })
  
  const handleImageUpload = async (event, imageType) => {   
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const imageSrc = e.target.result;
      const image = new Image();
      
      image.src = imageSrc

      image.onload = async () => {     

        const result = blurDetector(image, window.cv);
        const payload = {
          "image_hash": image.src,
          "imageType": imageType,
          "imageSrc": imageSrc,
          "inputBackRef": inputBackRef,
          "inputSideRef": inputSideRef
        }
        
        
        blurImageDetectorMutation.mutate(payload)
        // console.log(response);

      //   // if (result === 'Blur') {
      //   //   toggleBlurModal();
          
      //   //   if (imageType === 'back' && inputBackRef.current) {
      //   //     setImgSrcBack(null);
      //   //     inputBackRef.current.value = '';
      //   //   } else if (imageType === 'side' && inputSideRef.current) {
      //   //     setImgSrcSide(null);
      //   //     inputSideRef.current.value = '';
      //   //   }}


      // // else {
      //     if (imageType === 'back') {
      //       setImgSrcBack(imageSrc);
            
      //     } else if (imageType === 'side') {
      //       setImgSrcSide(imageSrc);
            
      //     }

      //     setShowNextButton(true);
      //   // }
      };

    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const deleteImage = (imageType) => {
    if (imageType === 'back') {
      setImgSrcBack(null);
      inputBackRef.current.value = '';
    } else if (imageType === 'side') {
      setImgSrcSide(null);
      inputSideRef.current.value = '';
    }

    setShowNextButton(false);
  };

  const submit = () => {
    nextBtn({ backImage: imgSrcBack, sideImage: imgSrcSide });
  };

  return (
    <>
    
    <div className="custom-container px-md-5 px-2 pt-md-5 pt-4 my-2">
        <div className="row">
          <div className="col-md-12 mx-auto justify-content-center">
            <div>
              <button onClick={toggleGuideModal} className="btn btn-secondary mb-2">
                Guide
              </button>
            </div>

            <div className="text-center my-3">
              <span>
                <h5>
                  Upload back image of the animal
                </h5>

                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, 'back')}
                  className="my-2"
                  ref={inputBackRef}
                  style={{
                    wordWrap: 'break-word', 
                  }}
                  />
                <br />

                  <h5 className='mt-3'>
                    Upload side image of the animal
                  </h5>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, 'side')}
                  className="my-2"
                  ref={inputSideRef}
                  style={{
                    wordWrap: 'break-word', 
                  }}
                />
              </span>
            </div>

            <div className="text-center my-3">
              <div className="row justify-content-center">
                <div className="col-md-4 col-12">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      border: '1px solid black',
                      marginBottom: '5px',
                    }}
                  >
                    <h3 className="my-md-4 my-2">Back Image</h3>
                    {imgSrcBack && (
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}>
                        <img ref={inputBackRef} src={imgSrcBack} alt="Back Captured" style={{ width: 'auto', height: 'auto' }} />
                        <button
                          onClick={() => deleteImage('back')}
                          className="btn btn-green mt-3 mb-4"
                          style={{ marginLeft: '-20px' }}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      border: '1px solid black',
                      marginBottom: '5px',
                    }}
                  >
                    <h3 className="my-md-4 my-2">Side Image</h3>
                    {imgSrcSide && (
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <img src={imgSrcSide} alt="Side Captured" style={{ width: 'auto', height: 'auto' }} />
                        <button
                          onClick={() => deleteImage('side')}
                          className="btn btn-green mt-3 mb-4"
                          style={{ marginLeft: '-20px' }}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {imgSrcBack && imgSrcSide && (
                <div className="text-end">
                  <button onClick={submit} className="btn btn-green mb-2">
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {
                String(species).includes('Elephant') &&
                <ElephantGuideModalComponent
                isOpen={isGuideModal}
                toggle={toggleGuideModal}
                />
              }


              {
                String(species).includes('Lemur') &&
                  <LemurGuideModalComponent
                  isOpen={isGuideModal}
                  toggle={toggleGuideModal}
                  />
              }

              {
                String(species).includes('Lion') &&
                  <LionGuideModalComponent
                  isOpen={isGuideModal}
                  toggle={toggleGuideModal}
                  />
              }

        <BlurModal isOpen={isBlurModal} toggle={toggleBlurModal} />
      </div>
    </>
  );
};

export default ImagesTemp;
