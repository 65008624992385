import { Navigate } from "react-router-dom";
import { useStore } from "../store/useStore";


export const AdminRoute = ({ children }) => {

    const userType = useStore((state) => state.userType);
    
    return (
  
        userType === 'admin' ? children : 
        <Navigate to="/login" />
  
    )
  }


export const ExpertRoute = ({ children }) => {

    const userType = useStore((state) => state.userType);
    
    return (
  
        userType === 'expert' ? children : 
        <Navigate to="/login" />
  
    )
  }
  

export const AgentRoute = ({ children }) => {

    const userType = useStore((state) => state.userType);
    
    return (
  
        userType === 'agent' ? children : 
        <Navigate to="/login" />
  
    )
  }


export const RedirectUser = ({ children }) => {
  
    const userStatus = useStore((state) => state.userStatus);
    const userType = useStore((state) => state.userType)
   
  if (userStatus && !!userType) {
    
      switch(userType){
        case ('agent'):
          return <Navigate to='/staff/alerts' />;
          
          case ('expert'):
          return <Navigate to='/expert/dashboard'/>;
          
          case ('admin'):
          return <Navigate to='/admin/dashboard'/>;

        default:
          break;

      } 

  } 
  
  return children;
  
  }