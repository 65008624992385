export const formatDate = (timestamp) => {
    try{

        const date = new Date(timestamp);
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based, so we add 1
        const year = date.getFullYear();
        
        const formattedDate = `${day}-${month}-${year}`;
        
        return formattedDate
    }
    catch {
        return 'N/A'
    }
}


export const formatRole = (roleID) => {
    if(roleID === 3) return 'Verifier'

    if (roleID === 4) return 'Assessor'

    return "N/A"

}

export const formatSectionString = (inputString) => {
    // Log the input string to the console for debugging purposes
    console.log('inputstring', inputString);

    // Check if the inputString is truthy (not null or undefined)
    if (inputString) {
        // Use a regular expression to match one or more digits in the inputString
        const matchResult = inputString.match(/\d+/);

        // Check if any digits were found
        if (matchResult && matchResult.length > 0) {
            const sectionNumber = matchResult[0];
            const formattedString = `Section ${sectionNumber}`;
            return formattedString;
        }
    }

    // If there are no digits or if the inputString is falsy, return 'N/A'
    return 'N/A';
};

export const convertTo24Hrs = (timestamp) => {
    const date = new Date(timestamp);

    const timeZoneOffset = new Date().getTimezoneOffset();

    date.setMinutes(date.getMinutes() - timeZoneOffset);

    const formattedTime = date.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',

    });

    return formattedTime;
};

export const convertToKG = (weight) => {
    return weight/1000
}


export const formatScore = (score) => {

        
        if (score === 1){
            return '1 - Extreme Low'
        }
        if (score === 3){
            return '3 - Low'
        }

         if (score === 5){
            return '5 - Moderate'
        }

         if (score === 7){
            return '7 - High'
        }

         if (score === 9){
            return '9 - Extremely High'
        }

            return score
    
}


export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const timeZoneOffset = new Date().getTimezoneOffset();

    date.setMinutes(date.getMinutes() - timeZoneOffset);

    const formattedTime = date.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
    });

    const formattedDate = date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    return `${formattedTime} -- ${formattedDate}`;
};