import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import { useMutation } from 'react-query';

import useAxios from '../../api/useAxios';



const DeleteModal = ({ isOpen, toggle, entity, refetch }) => {


    const api = useAxios()

    const deleteEntity = () => {
        return api.delete(`/${entity?.entity}/delete/${entity?.id}`)
    }

    const deleteMutation = useMutation(deleteEntity, {
        onSuccess: () => {
            toast.success('Deleted')
            toggle()
            refetch()
        },
        onError: () => {
            toast.error('Something went wrong')
        }
    })


  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
      <ModalBody>
        <Box sx={{ minWidth: 120 }}>
            Are you sure you want to delete {entity?.entity}: {entity?.representation}?
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button disabled={deleteMutation.isLoading} color="secondary" onClick={toggle}>
          No
        </Button>
        <Button disabled={deleteMutation.isLoading} onClick={deleteMutation.mutate} className="btn btn-danger">
          {deleteMutation.isLoading ? <>Deleting <Spinner size={'sm'}/></> : 'Yes'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
