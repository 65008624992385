import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/expert/dashboard',
    icon: icon('ic_dashboard'),
  },


  {
    title: 'Data Export',
    path: '/expert/reports',
    icon: icon('ic_report'),
  },

  {
    title: 'Alerts',
    path: '/expert/alerts',
    icon: icon('ic_alert'),
  },


];

export default navConfig;



