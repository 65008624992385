import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import { alertModalSchema } from '../../validations/validation-schemas';


import image1 from '../../asset/animals/lion/1.png'
import image3 from '../../asset/animals/lion/3.png'
import image5 from '../../asset/animals/lion/5.png'
import image7 from '../../asset/animals/lion/7.png'
import image9 from '../../asset/animals/lion/9.png'


const LionGuideModalComponent = ({ isOpen, toggle }) => {
  const formik = useFormik({
    initialValues: {
      user: '',
      remarks: '',
    },
    validationSchema: alertModalSchema,
    onSubmit: (values) => {
        console.log('submitting')
    },
  });

  const [modalStyles, setModalStyles] = useState({
    zIndex: '1',
  });



  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size='lg' style={modalStyles}>
      <ModalHeader toggle={toggle}>BCS Guide - Lions (Panthera leo)</ModalHeader>
      <ModalBody>

          <table className='guide-table'>
            
            <tr>
                <th>BCS</th>
                <th>Neck and Head</th>
                <th>Abdomen</th>
                <th>Hip Bones</th>
                <th>Example Photographs</th>
            </tr>
            
            <tr>
                <td className='bcs-score'>1</td>
                <td>Protruding scapula and lack of muscle over the shoulders, Noticable from a distance.</td>
                <td>All or most of ribs visible from a distance. </td>
                <td>Very prominent bony structures. Hip bones very prominent.</td>
                <td><img src={image1} alt="" /></td>
            </tr>

            <tr>
                <td className='bcs-score'>3</td>
                <td>Visible scapula with little muscle over the shoulders, thin neck .</td>
                <td>More than 2 ribs noticeable from a distance. </td>
                <td>Pelvic bones visible. </td>
                <td><img src={image3} alt="" /></td>
            </tr>
            
            <tr>
                <td className='bcs-score'>5</td>
                <td>Visible scapula with obvious muscle definition over shoulder.</td>
                <td>Not able to see distinct ribs </td>
                <td>Bony structure visible but not prominent; thigh muscle obvious while walking. </td>
                <td><img src={image5} alt="" /></td>
            </tr>
           
            <tr>
                <td className='bcs-score'>7</td>
                <td>Scapula not visible, rounded shoulder region with visible fat deposits, thick neck.</td>
                <td>Some fat evident over ribs.</td>
                <td>Slight definition of thighs with evident fat deposits. No bony structures visible.</td>
                <td><img src={image7} alt="" /></td>
            </tr>
           
            <tr>
                <td className='bcs-score'>9</td>
                <td>The neck is thick and blends into shoulders.</td>
                <td>Obvious fat deposits covering ribs and fat deposits along topline. </td>
                <td>No definition of thighs. Obvious fat deposits visible over back, pelvis and tail base.</td>
                <td><img src={image9} alt="" /></td>
            </tr>

          </table>
        
      </ModalBody>
      <ModalFooter>
        <buton className='btn btn-green' onClick={toggle}>
          Close
        </buton>
      </ModalFooter>
      <ToastContainer /> {/* Include ToastContainer to display toasts */}
    </Modal>
  );
};

export default LionGuideModalComponent;