import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

import Box from '@mui/material/Box';



const BlurModal = ({ isOpen, toggle }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Blur Image</ModalHeader>
      <ModalBody>
        <Box sx={{ minWidth: 120 }}>
            The uploaded image is blurred, please retry!
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BlurModal;
