import { Navigate, useRoutes } from 'react-router-dom';

import AdminDashboardLayout from './layouts/admin';
import ExpertDashboardLayout from './layouts/expert';
import StaffDashboardLayout from './layouts/staff';


import SimpleLayout from './layouts/simple';

import LoginPage from './pages/login-page';
import Page404 from './pages/Page404';

import AlertPage from './pages/staff/alert-page';
import DataEntry from './pages/staff/data-entry-page';


import ExpertDashboard from './pages/expert/expert-dashboard';
import ExpertAlerts from './pages/expert/experts-alerts';
import ExpertBCS from './pages/expert/experts-bcs';
import ExpertReports from './pages/expert/data-export';

import ForgotPassword from './pages/forgot-password';

import AdminDashboard from './pages/admin/dashboard';
import AdminUserRegistration from './pages/admin/user-registration';
import AdminAnimalRegistration from './pages/admin/animal-registration';


import { AgentRoute, AdminRoute, ExpertRoute, RedirectUser } from './utils/protectedRoutes'


export default function Router() {
  const routes = useRoutes([
    {
      element: <AdminDashboardLayout />,
      children: [
        { path: 'admin/dashboard', element: <AdminRoute><AdminDashboard /></AdminRoute> },
        { path: 'admin/user-registration', element: <AdminRoute><AdminUserRegistration /></AdminRoute> },
        { path: 'admin/animal-registration', element: <AdminRoute><AdminAnimalRegistration /></AdminRoute> },

      ],
    },
    {
      element: <ExpertDashboardLayout />,
      children: [
        
        { path: 'expert/dashboard', element: <ExpertRoute><ExpertDashboard /></ExpertRoute> },
        { path: 'expert/alerts', element: <ExpertRoute><ExpertAlerts /></ExpertRoute> },
        { path: 'expert/bcs', element: <ExpertRoute><ExpertBCS /></ExpertRoute> },
        { path: 'expert/reports', element: <ExpertRoute><ExpertReports /></ExpertRoute> },

      ],
    },
    {
      element: <StaffDashboardLayout />,
      children: [
        
        { path: 'staff/alerts', element: <AgentRoute><AlertPage /></AgentRoute> },
        { path: 'staff/data-entry', element: <DataEntry />},
      ],
    },
    {
      path: 'login',
      element: <RedirectUser><LoginPage /></RedirectUser>,
    },

    {
      path: 'forgot-password',
      element: <RedirectUser><ForgotPassword /></RedirectUser>,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
