import { useEffect, useState } from 'react';
import { Label, Input, Spinner } from 'reactstrap';

import { Link } from 'react-router-dom';

import { useForgotPassFormik } from '../formik/auth';
import useResetPassMutation from '../api/mutations/useForgotPassMutation';

import whiteLogo from '../asset/images/logos/white-logo.svg';


const ForgotPassword = () => {

  document.title = 'Forgot Password | Body Condition Scoring'

  const onSubmit = (values) => {
    resetPassMutation.mutate(values);
  };


  const [showLogin, setShowLogin] = useState(false);

  const { 
          values, 
          errors, 
          touched, 
          handleBlur, 
          handleChange, 
          handleSubmit,
          resetForm
        } = useForgotPassFormik(onSubmit);

  const resetPassMutation = useResetPassMutation();


  useEffect(() => {
    if (resetPassMutation.isSuccess) {
        const responseData = resetPassMutation.data;
        console.log('success', responseData);
    }
    else{
      console.log(resetPassMutation.error)
    }
  }, [resetPassMutation.isSuccess, resetPassMutation.data])

  return (
    <div className='login-wrapper login'>
      <div className='container h-100'>
        <div className='row justify-content-center align-items-center h-100'>
          <div className='col-md-6 col-12'>
            <div className='d-flex justify-content-center'>
              <img src={whiteLogo} className="img-fluid logo" alt="Not Found" />
            </div>

            <form onSubmit={handleSubmit} className='mt-3'>
              
                <h4 className='text-primary text-center mb-4'>Reset your password</h4>
              <Label className='text-primary'>Email*</Label>
              <Input
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                className={errors.email && touched.email ? "form-control form-control-icon is-invalid login-field col-6" : "form-control form-control-icon login-field col-6"}
                id="emailInput"
                placeholder="Enter Email"
                name="email"
              />
                {
                  errors.email && touched.email && <div className="invalid-feedback">{errors.email}</div>
                }

              <button disabled={resetPassMutation.isLoading} type='submit' className='btn btn-green mt-4'>
                {resetPassMutation.isLoading ? 
                  <span className='d-flex align-items-center justify-content-center'>
                    Processing <Spinner size="sm" className="flex-shrink-0 ms-2"/>
                  </span>  
                  :
                  <>
                    Submit
                  </>
              }
              </button>
              
              <div className='mt-2'>
              <Link to='/login' className='forgot-password-link' href="">Go Back to Login?</Link>
              </div>

            </form>
          </div>


        </div>
      </div>
    </div>
  );
}


export default ForgotPassword;