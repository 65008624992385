import {jwtDecode} from "jwt-decode";
import dayjs from 'dayjs'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { useStore } from '../store/useStore'
import IP from '../utils/variables';


const baseURL =  `https://${IP}/`


const useAxios = () => {
    
    const accessToken = useStore((state) => state?.accessToken)
    const refreshToken = useStore((state) => state?.refreshToken)

    console.log("_______________")
    console.log(refreshToken)
    console.log("_______________")

    const axiosInstance = axios.create({
        baseURL,
        headers:{Authorization: `Bearer ${accessToken}`}
    });

    const navigate = useNavigate()

    axiosInstance.interceptors.request.use(async req => {
        
        if (!accessToken){
            useStore.setState({
                userStatus: false,
                userType: null,
                accessToken: null,
                refreshToken: null,
                name: null,
                emai: null,
            })
            navigate('/login')
            return req
        }

            const user = jwtDecode(accessToken)
            
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
            
            if(!isExpired) return req
            
            const response = await axios.get(`${baseURL}auth/refresh?refresh_token=${refreshToken}`);
            
            useStore.setState({ accessToken: response.data?.access})
            
            req.headers.Authorization = `Bearer ${response.data?.access}`
            
            return req

    })

    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            if (error.response && error.response.status === 401 && error?.response?.data?.message !== 'Old password is incorrect.') {
                const response = await axios.get(`${baseURL}auth/refresh?refresh_token=${refreshToken}`).catch(err => {
                    useStore.setState({
                        userStatus: false,
                        userType: null,
                        accessToken: null,
                        refreshToken: null,
                        name: null,
                        emai: null,
                    })
                    navigate('/login')
                });
    
                console.log(response.data.access)

                useStore.setState({ accessToken: response.data.access});
    
                error.config.headers.Authorization = `Bearer ${response.data.access}`;
                return axiosInstance(error.config);
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance
}

export default useAxios;
