import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

import { toast } from 'react-toastify';

import Box from '@mui/material/Box';

import { useQuery, useMutation } from "react-query";


import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from "lightgallery/plugins/video";


import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";


import useAxios from '../../api/useAxios';
import { formatScore } from '../../utils/customFormatting';


const ModifyBCSModal = ({ isOpen, toggle, data, refetch }) => {

  const expertScore = data?.expert_score;

  const [expertValue, setExpertValue] = useState();

  const {
    isLoading,
    isSuccess,
    data: imagesData,
    isError,
    error,
    refetch: refetchImages
  } = useQuery(
    `bcs-images-${data?.id}`,
    async () => api.get(`bcs/get/${data?.id}`),
    { cacheTime: 0, enabled: !!data?.id } 
  );


  const images = imagesData?.data ?? {}

  useEffect(() => {
    
    if (data?.expert_score){
      setExpertValue(data.expert_score)
    }

  }, [data])


  const handleExpertValueChange = (event) => {
    setExpertValue(event.target.value); 
  };

  useEffect(() => {
    // Reset expertValue when the modal is closed
    if (!isOpen) {
      setExpertValue('');
    }
  }, [isOpen]);
    const api = useAxios()

    const updateValue = () => {
      return api.patch(`/bcs/update/${data?.id}/?expert_score=${expertValue}`)
    }

    const valueMutation = useMutation(updateValue, {
      onSuccess: () => {
        toast.success('Value updated')
        toggle()
        refetch()
      },
      onError: () => {
        toast.error('Something went wrong')

      }
    })

    const handleSideImgClick = () =>{
      document.getElementById('side-image').click()
      document.getElementsByClassName('modal')[0].style.display = 'none'

    }
    
    const handleBackImgClick = () =>{
      document.getElementById('side-image').click()
      document.getElementsByClassName('modal')[0].style.display = 'none'
    }
    
    const increaseOpacity = () => {
      document.getElementsByClassName('modal')[0].style.display = 'block'
    }
    return (
      <div>

      
    <Modal isOpen={isOpen} toggle={toggle} centered size='md'>
      <ModalHeader toggle={toggle}>BCS Details</ModalHeader>
      <ModalBody>
        <Box sx={{ minWidth: 120 }}>
        <div className='row'>

        <div className="col-6 py-2 mb-2">
          <div className='p-2'>
            <h5 className='text-bold'>Species</h5>
              {data?.species}
          </div>
        </div>
       
        <div className="col-6 py-2 mb-2">
          <div className='p-2'>
            <h5 className='text-bold'>Animal ID</h5>
              {data?.animal_id}
          </div>
        </div>

        <div className="col-6 py-2 mb-2">
          {
            data?.assessor_name && 
              <div className='p-2'>
                <h5 className='text-bold'>Assessor Name</h5>
                  {data?.assessor_name ? data.assessor_name : 'N/A'}
              </div>
          }
          {
            data?.expert_name && 
              <div className='p-2'>
                <h5 className='text-bold'>Verifier Name</h5>
                  {data?.expert_name ? data.expert_name : 'N/A'}
              </div>
          }
        </div>

        <div className="col-6 py-2 mb-2">
          <div className='p-2'>
            <h5 className='text-bold'>Assessor Score</h5>
              {data?.agent_score ? formatScore(data.agent_score) : 'N/A'}
          </div>
        </div>
        
        <div className="col-6 py-2 mb-2">
          <div className='p-2'>
            <h5 className='text-bold'>Verifier Score</h5>
              <select value={expertValue} onChange={handleExpertValueChange} defaultValue={expertScore}>
                <option value="">Choose</option>
                <option value="1">1 - Extremely Low</option>
                <option value="3">3 - Low</option>
                <option value="5">5 - Moderate</option>
                <option value="7">7 - High</option>
                <option value="9">9 - Extremely High</option>
              </select>
          </div>
        </div>

        <div className="col-12 py-2 mb-2">
          <div className='p-2'>
            <h5 className='text-bold'>Remarks</h5>
           {data?.remarks ? data.remarks : 'N/A'}
          </div>
        </div>

        {
          isLoading && 
          <div className='text-center'>
            <h5>Loading Images</h5>
            <Spinner size={'sm'}/>
          </div>
        }
        {
          isSuccess &&
          <div className='row'>
          {images.back_image && (
            <div className="col-md-6 py-2 mb-2">
              <div className='p-2'>
                <h5 className='text-bold'>Back Image</h5>
                <button
                  type="button"
                  onClick={handleBackImgClick}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleBackImgClick()
                    }
                  }}
                  className="image-button"
                  style={{border: 'none'}}
                  >
                  <img
                    
                    style={{ width: '200px', height: 'auto' }}
                    src={images?.back_image}
                    alt="Back"
                    className="img-fluid border-green w-100"
                    />
                </button>
              </div>
            </div>
          )}
        
          {images?.side_image && (
            <div className="col-md-6 py-2 mb-2">
              <div className='p-2'>
                <h5 className='text-bold'>Side Image</h5>
                <button
                  style={{border: 'none'}}
                  type="button"
                  onClick={handleSideImgClick}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSideImgClick()
                    }
                  }}
                  className="image-button"
                >
                  <img
                    
                    style={{ width: '200px', height: 'auto' }}
                    src={images?.side_image}
                    alt="Side"
                    className="img-fluid border-green w-100"
                  />
                </button>
              </div>
            </div>
          )}
        </div>
        
        }

        {
          isError && 
          <h5>Images not found!</h5>
        }

       


      </div>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button disabled={valueMutation.isLoading} onClick={valueMutation.mutate} className="btn btn-green">
          {
          valueMutation.isLoading ? 
          <>Updating <Spinner size={'sm'}/></>
          :
          'Update'
        }
        </Button>
      </ModalFooter>
      
    </Modal>
    <LightGallery
        onBeforeClose={increaseOpacity}
        speed={500}
        plugins={[lgZoom]}
        mobileSettings={
          {download: true}
        }
        >
          {
            images?.back_image &&
          <a id='back-image' href={images?.back_image}>
            <img src={images?.back_image} id='lg-group' className='d-none' alt="Back"/>
          </a>
          }
          
          {
            images?.side_image &&
          <a id='side-image' href={images?.side_image}>
            <img src={images?.side_image} id='lg-group' className='d-none' alt="Side"/>
          </a>
          }
          </LightGallery>
    </div>
    )

}


export default ModifyBCSModal;