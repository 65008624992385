import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { Label, Input,Spinner } from 'reactstrap';

import { useMutation } from 'react-query';
import { userSchema } from '../../validations/validation-schemas';

import useAxios from '../../api/useAxios';

const AdminUserRegistration = () => {
  document.title = 'User Registration | BCS';


  const onSubmit = () => {
    userRegMutation.mutate(values)
  }


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confpassword: '',
      role_id: '',
      department: ''
    },
    validationSchema: userSchema,
    onSubmit,
    enableReinitialize: true,
  })

  const api = useAxios()

  const userReg = (payload) => {
    console.log(payload)
    return api.post('/user/invite', payload)
  }

  const userRegMutation = useMutation(userReg, {
    onSuccess: (response) => {
      console.log('success', response)
      if (response?.data?.success){
        toast.success('User account created')
      }
      // else if (!response?.data?.success && response?.data?.message){

      //   toast.error(response.data.message.toUpperCase())
      // }
      else{
        toast.error('Something went wrong')
      }
      
      resetForm()
    },
    onError: (response) => {
      
      if (response?.response?.status === 422 && response?.response?.data?.detail[0]?.msg === 'ensure this value has at least 8 characters'){
        toast.error('Password must be atleast 8 characters')
    }else{

      toast.error('Something went wrong')
    }
      
    }
  })


  return (
    <div className='container'>
      <div className="container-fluid breadcrumb-color d-flex align-items-center mb-3" style={{ borderRadius: '10px' }}>
        <nav aria-label="breadcrumb" className="d-flex align-items-center">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item text-white text-center active fs-4 pt-3 ps-2 br-10"
              aria-current="page"
            >
              User Registration
            </li>
          </ol>
        </nav>
      </div>
      <div className="container-fluid bg-white pb-3 custom-container">
          
          <form onSubmit={handleSubmit}>
              <div className='row p-3'>
                <div className="col-12 col-md-6 mt-3">
                  <Label>Name*</Label>             
                  <Input
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      placeholder="Enter user's name"
                      className={errors.name && touched.name ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                      id="nameInput"
                      name="name"
                    />
                    {
                      errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>
                    }
                </div>
               
                <div className="col-12 col-md-6 mt-3">
                  <Label>User Role*</Label>                  
                  <Input
                    value={values.role_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={errors.role_id && touched.role_id ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="role_idInput"
                    name="role_id"
                  >
                    <option value="">Choose</option>
                    <option value="3">Verifier</option>
                    <option value="4">Assessor</option>
                  </Input>
                  {
                    errors.role_id && touched.role_id && <div className="invalid-feedback">{errors.role_id}</div>
                  }

                </div>
               
                <div className="col-12 col-md-6 mt-3">
                  <Label>Email Address*</Label>                  
                  <Input
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    placeholder="Enter user's email"
                    className={errors.email && touched.email ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="emailInput"
                    name="email"
                  />
                   {
                    errors.email && touched.email && <div className="invalid-feedback">{errors.email}</div>
                  }

                </div>
               
                <div className="col-12 col-md-6 mt-3">
                  <Label>Password*</Label>                  
                  <Input
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    placeholder="Enter user's password"
                    className={errors.password && touched.password ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="passwordInput"
                    name="password"
                  /> {
                    errors.password && touched.password && <div className="invalid-feedback">{errors.password}</div>
                  }
                </div>
               
                <div className="col-12 col-md-6 mt-3">
                  <Label>Confirm Password*</Label>                  
                  <Input
                    value={values.confpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    placeholder="Confirm Password"
                    className={errors.confpassword && touched.confpassword ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="passwordInput"
                    name="confpassword"
                  /> {
                    errors.confpassword && touched.confpassword && <div className="invalid-feedback">{errors.confpassword}</div>
                  }
                </div>
            
                <div className="col-12 col-md-6 mt-3">
                  <Label>Department*</Label>                  
                  <Input
                    value={values.department}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="select"
                    className={errors.department && touched.department ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon col-6"}
                    id="departmentInput"
                    name="department"
                  > 
                      <option value="">Select Department</option>
                      <option value="Animal Care">Animal Care</option>
                      <option value="Veterinary Healthcare">Veterinary Healthcare</option>
                      <option value="Animal Behaviour & Programmes">Animal Behaviour & Programmes</option>
                  </Input>
                  
                  {
                    errors.department && touched.department && <div className="invalid-feedback">{errors.department}</div>
                  }
                </div>
                
              <div className='text-start mt-3'>
                <button disabled={userRegMutation.isLoading} type='submit' className="btn btn-green">
                    {userRegMutation.isLoading ? <>Processing <Spinner size='sm'/></> : 'Register'}
                  </button>
              </div>
              </div>

          </form>

      </div>
    </div>
  );
};

export default AdminUserRegistration;
