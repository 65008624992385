import React from 'react';

const NAV_WIDTH = 280;

const FooterComponent = () => {
  const footerStyle = {
    backgroundColor: '#003400',
    position: 'absolute',
    textAlign: 'center',
    bottom: 0,
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    padding: '15px 0',
    fontWeight: '400',
    fontSize: '18px',
    color: 'white',
    zIndex: 999,
  };

  const containerStyle = {
    minHeight: '10vh',
    paddingBottom: '60px',
  };

  const pathname = window.location.pathname;

  if (window.innerWidth <= 1200 || pathname.includes('login') || pathname.includes('staff')) {
    footerStyle.width = '100%';
  }

  return (
    <div style={containerStyle}>
      ""
    </div>
  );
};

export default FooterComponent;


