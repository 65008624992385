import { useFormik } from 'formik';
import { adminLoginSchema, forgotPasswordSchema } from '../validations/validation-schemas';

const useLoginFormik = (onSubmit) => {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: adminLoginSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return { values, errors, touched, handleBlur, handleChange, handleSubmit };
};



const useForgotPassFormik = (onSubmit) => {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: forgotPasswordSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return { values, errors, touched, handleBlur, handleChange, handleSubmit };
};


export { useLoginFormik, useForgotPassFormik };