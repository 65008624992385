import React from 'react';
import Swal from 'sweetalert2';

import evaluateResult from '../../utils/evaluateResult';

const ScoringChart = ({ finalValues, moveToFirstComponent }) => {

  const tableCellStyle = {
    padding: '20px',
    border: '1px solid #003400'
  };
  
  const tableHeaderStyle = {
    padding: '20px',
    backgroundColor: '#003400',
    fontWeight: 'bold',
    color: 'white'
  };

  let algorithmData = finalValues?.algorithmScore 

  if (algorithmData === 0){
    algorithmData = 'There was an issue with the images, Please try again.'
  }

  return (
    <>
        <div className="custom-container px-md-5 px-2 pt-md-5 pt-4 my-2">
            <div className="row">
              <div className="col-12">
                <table className="table table-bordered shadow ">
                  <thead className='table-header table-cell-style'>
                  

                    <tr>
                      <th className="text-center table-header table-cell-style">
                        BCS
                      </th>
                      <th className="text-center table-header table-cell-style">
                        Assessor's Data
                      </th>
                      <th className="text-center table-header table-cell-style">
                        Algorithm Data
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center table-cell-style">{finalValues?.species}</td>
                      <td className="text-center table-cell-style">{finalValues?.agentScore ?? 'N/A'}</td>
                      <td className="text-center table-cell-style">{algorithmData}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          <div className="text-center py-3 mx-2">
          
            <h5 className="text-start fw-normal">{evaluateResult(finalValues?.agentScore, finalValues?.algorithmScore)}</h5>
          
          </div>
          <div className='pb-3 text-end'>
            <button className="btn btn-green" onClick={moveToFirstComponent}>
              Go to Scoring
              </button>
          </div>
      </div>
    </>
  );
};

export default ScoringChart;
