import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { useQuery } from 'react-query';

import DeleteModal from '../../components/common/delete-modal.component';
import { formatDate, formatRole, convertToKG, formatTimestamp } from '../../utils/customFormatting'

import useAxios from '../../api/useAxios';
import AnimalModal from '../../components/common/animal-modal.component';
import UserModal from '../../components/common/user-modal.component';


const AdminDashboard = () => {
  document.title = 'Admin Dashboard | BCS';

  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [isAnimalModalOpen, setAnimalModalOpen] = useState(false);
  
  const [isDeleteModalOpen, setDeleteModal] = useState(false)

  const [deleteEntity, setDeleteEntity] = useState(false)

  const [activeAnimal, setActiveAnimal] = useState(false)
  const [activeUser, setActiveUser] = useState(false)


  const toggleDeleteModal = () => {
    setDeleteModal(!isDeleteModalOpen)
  }

  const toggleAnimalModal = () => {

    if (isAnimalModalOpen){
      setActiveAnimal(false)
    }

    setAnimalModalOpen(!isAnimalModalOpen)

  }

  const toggleUserModal = () => {

    if (isUserModalOpen){
      setActiveUser(false)
    }

    setUserModalOpen(!isUserModalOpen)

  }


  useEffect(() => {
      if (activeAnimal){
        toggleAnimalModal()
      }
  }, [activeAnimal])
  

  useEffect(() => {
      if (activeUser){
        toggleUserModal()
      }
  }, [activeUser])

  useEffect(() => {
      if (deleteEntity){
        setDeleteModal(true)
      }
  }, [deleteEntity])

  const api = useAxios()


  const { isLoading: usersLoading,
          isSuccess,
          data: usersRes,
          isError: isUsersError,
          error,
          refetch: refetchUsers
        } = useQuery('usersData', async () => api.get('user/get/all'), {cacheTime: 0})

  const users = usersRes?.data?.items ?? []

  
  const { isLoading: animalsLoading,
    isSuccess: animalsSuccess,
    data: animalsRes,
    isError: isAnimalsError,
    error: animalsError,
    refetch: refetchAnimals,
  } = useQuery('animalsData', async () => api.get('animal/get/all'), {cacheTime: 0})

const animals = animalsRes?.data?.items ?? []

  const userColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorFn: (row) => formatRole(row.role_id),
        header: 'Role',
      },
      {
        header: 'Department',
        accessorKey: 'department'
      },

      {
        accessorKey: 'email',
        header: 'ID (Email)',
      },
      {
        accessorFn: (row) => formatDate(row.created_on),
        header: 'Date Generated',
      },
      {
        header: 'Modify',
        accessorKey: 'edit',
        Cell: ({ row }) => (
          <button onClick={() => setActiveUser(row.original)} className='btn btn-primary'><BsPencil /></button>
        )

      },
      {
        header: 'Delete',
        accessorKey: 'delete',
        Cell: ({ row }) => (
          <button onClick={() => setDeleteEntity({'entity': 'user', 'id': row?.original?.id, 'representation': row?.original?.name})} className='btn btn-danger'><BsTrash /></button>
          )
      },
    ],
  );


  const animalColumns = useMemo(
    () => [
      {
        accessorFn: (row) => formatTimestamp(row?.created_on),
        'header': 'Created On',
        
      },
      {
        accessorFn: (row) => formatTimestamp(row?.last_updated),
        'header': 'Last Updated',
        
      },
      {
        header: 'Park',
        accessorKey: 'park'
      },
      {
        header: 'Species',
        accessorKey: 'species'
      },
      {
        header: 'Section',
        accessorKey: 'section',
        Cell: ({row}) => (row?.original?.section)
      },
      {
        header: 'Animal ID',
        accessorKey: 'animal_id'
      },
      {
        accessorFn: (row) => row?.gender?.charAt(0).toUpperCase() + row?.gender?.slice(1),
        header: 'Gender',
      },
      {
        header: 'DoB',
        accessorKey: 'date_of_birth'
      },
      {
        accessorFn: (row) => `${row?.weight > 999 ? `${convertToKG(row?.weight)} KG` : row?.weight === 0 ? '-' : `${row?.weight} G` }  `,
        header: 'Weight',
        accessorKey: 'weight'
      },

      {
        header: 'Modify',
        accessorKey: 'edit',
        Cell: ({ row }) => (
          <button onClick={() => setActiveAnimal(row.original)} className='btn btn-primary'><BsPencil /></button>
          )
      },
      {
        header: 'Delete',
        accessorKey: 'delete',
        Cell: ({ row }) => (
          <button onClick={() => setDeleteEntity({'entity': 'animal', 'id': row?.original?.id, 'representation': row?.original?.animal_id})} className='btn btn-danger'><BsTrash /></button>          
          )
      }
      
    ]
  );

  return (
    <div className='container mb-4'>
        <div className="container-fluid breadcrumb-color d-flex align-items-center mb-3 br-10 shadow">
          <nav aria-label="breadcrumb" className="d-flex align-items-center">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item text-white text-center active fs-4 pt-3 ps-2 text-bold"
                aria-current="page">
                Dashboard
              </li>
            </ol>
          </nav>
        </div>

      <div
        className="container-fluid my-md-3 my-2 bg-white pb-md-5 pb-3 custom-container"
      >
        <h4 className="text-primary text-start m-3 pt-2">Existing Users Data</h4>

        <div className="container-fluid px-3 bg-white">

          {isUsersError ? 
          'Something went wrong':
          
          <MaterialReactTable
            columns={userColumns}
            enableRowNumbers
            data={users}
            enableSorting
            enableMultiSort
            state={{isLoading: usersLoading}}
            muiTablePaperProps={{
              elevation: 5,
              sx: {
                borderRadius: '2px',
                border: '1px solid lightgray'
              },
          }}
          initialState={{
            columnPinning: {right: ['edit', 'delete']}
          }}
          />
}
        </div>
      </div>

      <div className="container-fluid bg-white pb-5 custom-container">

        <h4 className="text-primary text-start m-3 pt-2">Existing Animals Data</h4>

        <div className="container-fluid bg-white px-3">

        {isAnimalsError ? 'Something went wrong' : 
          <MaterialReactTable
            columns={animalColumns}
            data={animals}
            enableRowNumbers
            enableSorting
            state={{isLoading: animalsLoading}}
            muiTablePaperProps={{
              elevation: 5,
              sx: {
                borderRadius: '2px',
                border: '1px solid lightgray'
              },
            }}
            initialState={{
              columnPinning: {right: ['edit', 'delete']}
              
            }}
          />}

        </div>
      </div>

      <AnimalModal
          isOpen={isAnimalModalOpen}
          toggle={toggleAnimalModal}
          animal={activeAnimal}
          refetch={refetchAnimals}
      />
      
      <UserModal
          isOpen={isUserModalOpen}
          toggle={toggleUserModal}
          user={activeUser}
          refetch={refetchUsers}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        entity={deleteEntity}
        refetch={deleteEntity?.entity === 'animal' ? refetchAnimals : refetchUsers}
      />

    </div>
  );
};

export default AdminDashboard;
