const blurDetector = (image, cv) => {

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 500;
        ctx.drawImage(image, 0, 0, 500, 500);
        const src = cv.imread(canvas);
      
        const gray = new cv.Mat();
        cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);
      
        const laplacian = new cv.Mat();
        cv.Laplacian(gray, laplacian, cv.CV_64F);

        const mean = new cv.Mat();
        const stdDev = new cv.Mat();
        cv.meanStdDev(laplacian, mean, stdDev);
      
        const laplacianVariance = stdDev.data64F[0] ** 2;
      
        const threshold = 8;
        let result;
        console.log(laplacianVariance)
      
        if (laplacianVariance < threshold) {
          result = 'Blur';
        } else {
          result = 'Not Blur';
        }
      
        src.delete();
        gray.delete();
        laplacian.delete();
        mean.delete();
        stdDev.delete();
      
        return result;
      }

export default blurDetector;