import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header';
import ExpertNav from './nav/expert-team-index';
import FooterComponent from '../../components/footer/footer';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  // overflow: 'hidden',
  zIndex: '-1000000000'
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(0),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayoutAdmin() {
  const [open, setOpen] = useState(false);

  const [marginStyle, setMarginStyle] = useState({});
  useEffect(() => {
    const updateMargin = () => {
      const screenSize = window.innerWidth;
      if (screenSize <= 768) {
        setMarginStyle({ marginLeft: '0', marginRight: '0' });
      } else {
        setMarginStyle({ marginLeft: '-16px', marginRight: '-16px' });
      }
    };
    updateMargin();
    window.addEventListener('resize', updateMargin);

    return () => {
      window.removeEventListener('resize', updateMargin);
    };
  }, []);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <ExpertNav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />

        {/* <div style={marginStyle}> */}
          {/* <FooterComponent /> */}
        {/* </div> */}
      </Main>
    </StyledRoot>
  );
}
