import { Label, Input, Spinner } from 'reactstrap';

import { Link } from 'react-router-dom';

import { useLoginFormik } from '../formik/auth';

import useLoginMutation from '../api/mutations/useLoginMutation';

// import whiteLogo from '../asset/images/logos/white-logo.svg';
import whiteLogo from '../asset/images/logos/mandai-green.png';

const LoginPage = () => {

  document.title = 'Login | Body Condition Scoring'


  const onSubmit = (values) => {
    loginMutation.mutate(values);
  };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useLoginFormik(onSubmit);
    const loginMutation = useLoginMutation();

  return (
    <div className='login-wrapper login'>
      <div className='container h-100'>
        <div className='row justify-content-center align-items-center h-100'>
          <div className='col-md-6 col-12'>
            <div className='d-flex justify-content-center'>
              <img src={whiteLogo} className="img-fluid logo" alt="Not Found" />
            </div>

            <form onSubmit={handleSubmit} className='mt-3'>
              <Label className='text-primary'>Email*</Label>
              <Input
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                className={errors.username && touched.username ? "form-control form-control-icon is-invalid login-field col-6" : "form-control form-control-icon login-field col-6"}
                id="emailInput"
                placeholder="Enter Email"
                name="username"
              />
                {
                    errors.username && touched.username && <div className="invalid-feedback">{errors.username}</div>
                  }

              <Label className='mt-4 mb-0 text-primary'>Password*</Label>
              <Input
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                className={errors.password && touched.password ? "form-control form-control-icon is-invalid login-field col-6" : "form-control form-control-icon login-field col-6"}
                id="passwordInput"
                placeholder="Enter Password"
                name="password"
              />
              {
                errors.password && touched.password && <div className="invalid-feedback">{errors.password}</div>
              }

              <button disabled={loginMutation.isLoading} type='submit' className='btn btn-green mt-4'>
                {loginMutation.isLoading ? 
                  <span className='d-flex align-items-center justify-content-center'>
                    Logging In <Spinner size="sm" className="flex-shrink-0 ms-2"/>
                  </span>  
                  :
                  <>
                    Log In
                  </>
              }
              </button>
              <div className='mt-2'>
              <Link to='/forgot-password' className='forgot-password-link' href="">Forgot Password?</Link>
              </div>
            </form>
          </div>


        </div>
      </div>
    </div>
  );
}


export default LoginPage;