import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { useQuery } from 'react-query';
import { mkConfig, generateCsv, download } from 'export-to-csv'; 

import useAxios from '../../api/useAxios';
import { formatDate, convertTo24Hrs, convertToKG, formatTimestamp } from '../../utils/customFormatting';


const ExpertReports = () => {
  document.title = 'Data Export | BCS';

  const api = useAxios()

  const { 
    isLoading,
    isSuccess,
    data,
    isError,
    error,
    refetch,
  } = useQuery('export-data', async () => api.get('bcs/get/completed'))


  const animalsData = data?.data?.items ?? []

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'last_updated',
        Cell: ({row}) => (formatDate(row?.original?.last_updated))
      },
      {
        header: 'Time',
        accessorKey: 'last_updated',
        Cell: ({row}) => (convertTo24Hrs(row?.original?.last_updated))
      },
      {
        header: 'Species',
        accessorKey: 'species',
        
      },
      
      {
        header: 'Section',
        accessorKey: 'section',

      },

      {
        header: 'Assessor Name',
        accessorKey: 'assessor_name'
      },
      {
        header: 'Verifier Name',
        accessorKey: 'verifier_name'
      },
      {
        header: 'Animal ID',
        accessorKey: 'animal_id'
      },
      {
        header: 'Weight',
        accessorKey: 'weight',
        Cell: ({ row }) => row?.original?.weight > 999 ? `${convertToKG(row?.original?.weight)} KG` : row?.original?.weight === 0 ? '-' : `${row?.original?.weight} G` ,
      },
      {
        header: 'Weight Taken Time',
        accessorKey: 'animal_weight_taken',
        Cell: ({ row }) => formatTimestamp(row?.original?.animal_weight_taken),
      },

          {
            header: 'Assessor Score',
            accessorKey: 'agent_score'
          },
          {
            header: 'Algorithm Score',
            accessorKey: 'algorithm_score'
          },
          {
            header: 'Verifier Score',
            accessorKey: 'expert_score'
          },
    ],
  );

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    
    const rowData = rows.map((row) => {
      const modifiedOriginal = { ...row.original }; // Create a copy of the original object
  
      // Remove 'back_image' and 'side_image' properties
      delete modifiedOriginal.back_image;
      delete modifiedOriginal.side_image;
  
      return modifiedOriginal;
    });

    const csv = generateCsv(csvConfig)(rowData);

      const headers = {
        'Content-Type': 'text/csv',
        'Content-Disposition': 'attachment; filename="BCS Data.csv"', 
      };

      download({ headers })(csv);
  };

  return (
    <div className='container'>
      <div className="container-fluid breadcrumb-color d-flex align-items-center mb-3" style={{ borderRadius: '10px' }}>
        <nav aria-label="breadcrumb" className="d-flex align-items-center">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item text-white text-center active fs-4 pt-3 ps-2"
              aria-current="page"
              style={{ fontWeight: '800', borderRadius: '10px' }}
            >
              Data Export
            </li>
          </ol>
        </nav>
      </div>

      <div className="container-fluid bg-white custom-container">

        <div className="container-fluid bg-white">

          <div className="container-fluid bg-white p-3">
            <MaterialReactTable
              state={isLoading}
              columns={columns}
              data={animalsData}
              positionToolbarAlertBanner="bottom"
              enableRowNumbers
              renderTopToolbarCustomActions={({ table }) => (
                <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', marginTop: '10px' }}>
                  <Button
                    className="btn-styling"
                    onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                    variant="contained">
                    Export Data
                  </Button>
                </Box>
              )}
              muiTablePaperProps={{
                elevation: 5,
                sx: {
                  border: '1px solid lightgray'
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertReports;
