import * as Yup from 'yup';

export const adminLoginSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
})

export const dataEntryValidationSchema = Yup.object().shape({
  park: Yup.string(),
  section: Yup.string(),
  species: Yup.string(),
  id: Yup.number().required('Required'),
  animal_id: Yup.string().required('Required'),
  weight: Yup.number(),
  unit: Yup.string(),
  remarks: Yup.string(),
});

export const BCSSchema = Yup.object().shape({
  bcs: Yup.string().required('Required'),
})

export const bcsScoringValidationSchema = Yup.object().shape({
  category: Yup.string()
    .notOneOf(['Select a category'], 'Please select a category')
    .required('Category is required'),
});


export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});


export const modalValidationSchema = Yup.object().shape({
  expertScore: Yup.number().required('Score is required'),
  remarks: Yup.string().required('Remarks are required'),
});


const GenerateAlertSchema = Yup.object().shape({
  expertScore: Yup.number().required('User to Assign is required'),
  remarks: Yup.string().required('Remarks is required'),
});


export const userSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().required('Password is required'),
  // password: Yup.string().required('Password is required').min(8, 'Password must have at least 8 characters'),
  confpassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  role_id: Yup.number().required('User Role is required'),
  department: Yup.string().required('Department is reqiured')
});


export const userModifySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string(),
  role_id: Yup.number().required('Required'),
});


export const animalSchema = Yup.object().shape({
  park: Yup.string().required('Required'),
  section: Yup.string().required('Required'),
  species: Yup.string().required('Required'),
  animal_id: Yup.string().required('Required'),
  date_of_birth: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  weight: Yup.number().required('Required'),
  unit: Yup.string().required('Required')
});


export const passwordSchema = Yup.object().shape({
  old_password: Yup.string().required('Required'),
  new_password: Yup.string()
    .required('Required')
    .test('min-length', 'Password must be at least 8 characters long', (value) => {
      return value && value.length >= 8;
    }),
    confirm_new_password: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),

})


export const ExpertModalSchema = Yup.object().shape({
  expertScore: Yup.number()
    .required('Verifier Score is required')
    .min(1, 'Verifier Score should be at least 1')
    .max(9, 'Verifier Score should be at most 9'),
  remarks: Yup.string().required('Remarks are required'),
});


export const alertModalSchema = Yup.object().shape({
  user: Yup.string().required('User is required'),
  remarks: Yup.string().required('Remarks are required'),
});


export const modifyUserSchema = Yup.object().shape({
  userName: Yup.string().required('Name is required'),
  UserID: Yup.string().required('ID is required'),
});